import {
  ATHLETE_SPORTS_DETAILS_SUCCESS,
  ATHLETE_ACAD_DETAILS_SUCCESS,
  ATHLETE_SPORTS_UPDATE_SUCCESS,
  ATHLETE_ACAD_UPDATE_SUCCESS,
  ATHLETE_AGENT_SUCCESS,
  ATHLETE_AGENT_UPDATE_SUCCESS,
} from '../actions/athlete';

const initialState = {
  sport: null,
  sportDetails: null,
  acadDetails: null,
  agent: null,
  lastAddedAgentId: null,
};

export const athleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATHLETE_SPORTS_DETAILS_SUCCESS:
      return Object.assign({}, state, { sportDetails: action.payload });
    case ATHLETE_SPORTS_UPDATE_SUCCESS:
      const updated_info = { ...state.sportDetails, ...action.payload };
      return Object.assign({}, state, { sportDetails: updated_info });
    case ATHLETE_ACAD_DETAILS_SUCCESS:
      return Object.assign({}, state, { acadDetails: action.payload });
    case ATHLETE_ACAD_UPDATE_SUCCESS:
      const updated_acad_info = { ...state.acadDetails, ...action.payload };
      return Object.assign({}, state, { acadDetails: updated_acad_info });
    case ATHLETE_AGENT_SUCCESS:
      return Object.assign({}, state, { agent: action.payload });
    case ATHLETE_AGENT_UPDATE_SUCCESS:
      const updated_agent_info = { ...state.agent, ...action.payload };
      return Object.assign({}, state, {
        agent: updated_agent_info,
        lastAddedAgentId: action.payload.mysql_agent_id,
      });
    default:
      return state;
  }
};

export default athleteReducer;

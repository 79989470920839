import { getRequest, postJSONRequest } from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const TRYOUT_ADD_REQUEST = 'TRYOUT_ADD_REQUEST';
export const TRYOUT_ADD_SUCCESS = 'TRYOUT_ADD_SUCCESS';
export const TRYOUT_ADD_FAILURE = 'TRYOUT_ADD_FAILURE';
export const TRYOUT_DETAILS_REQUEST = 'TRYOUT_DETAILS_REQUEST';
export const TRYOUT_DETAILS_SUCCESS = 'TRYOUT_DETAILS_SUCCESS';
export const TRYOUT_DETAILS_FAILURE = 'TRYOUT_DETAILS_FAILURE';

const requestFetchDetails = () => {
  return {
    type: TRYOUT_DETAILS_REQUEST,
  };
};

const receiveFetchDetails = (tryoutId, profile) => {
  return {
    type: TRYOUT_DETAILS_SUCCESS,
    payload: { [tryoutId]: profile },
  };
};

const fetchDetailsError = (error) => {
  return {
    type: TRYOUT_DETAILS_FAILURE,
    payload: error,
  };
};

const requestAddProfile = () => {
  return {
    type: TRYOUT_ADD_REQUEST,
  };
};

const receiveAddProfile = (tryoutData) => {
  return {
    type: TRYOUT_ADD_SUCCESS,
    payload: tryoutData,
  };
};

const addTryoutError = (error) => {
  return {
    type: TRYOUT_ADD_FAILURE,
    payload: error,
  };
};

export const add = (instId, sportId, data) => async (dispatch) => {
  dispatch(requestAddProfile());
  try {
    const response = await fetch(
      postJSONRequest(
        `${gt_config.urls.sports}advert_add/${instId}/${sportId}`,
        data,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveAddProfile(json.data));

    return dispatch(addTryoutError(json.msg));
  } catch (error) {
    console.error(error);
    return dispatch(addTryoutError(error));
  }
};

export const fetchDetails = (tryoutId) => async (dispatch) => {
  dispatch(requestFetchDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}advert/${tryoutId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveFetchDetails(tryoutId, json.data));
  } catch (error) {
    console.error(error);
    return dispatch(fetchDetailsError(error));
  }
};

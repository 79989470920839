import { gt_config } from '../configs';
import isEmpty from 'lodash/isEmpty';

/**
 * Function to get avatar and cover of Version 0 users
 *
 * @param {*} image_path
 * @returns {string} URL to the actual image
 */
export const getV0Image = (image_path) => {
  if (
    typeof process.env.PUBLIC_URL === 'undefined' ||
    isEmpty(process.env.PUBLIC_URL) ||
    process.env.PUBLIC_URL === '/'
  ) {
    return `${gt_config.host}${image_path}`;
  } else {
    return `${process.env.PUBLIC_URL}${image_path}`;
  }
};

/**
 * Function to get avatar and cover of Version 1 users
 *
 * @param {number} image_id
 * @param {string} image_size small | medium | large | original
 * @returns {string} URL to the actual image
 */
export const getV1Image = (image_id, image_size = 'small') => {
  return `${gt_config.urls.media}/image/${image_id}/${image_size}`;
};

/**
 * Function to get video
 *
 * @param {number} video_id
 * @returns {string} URL to the actual video
 */
export const getV1Video = (video_id) => {
  return `${gt_config.urls.media}/document/${video_id}`;
};

/**
 * Function to get avatar images for users who dont have it
 *
 * @param {String} name to be converted into img data URI
 */
export const generateAvatar = (name) => {
  if (isEmpty(name)) return '';
  var initials = name
    .split(' ')
    .map(function (str) {
      return str ? str[0].toUpperCase() : '';
    })
    .join('');
  var canvas = document.createElement('canvas');
  var radius = 30;
  var margin = 5;
  canvas.width = radius * 2 + margin * 2;
  canvas.height = radius * 2 + margin * 2;

  // Get the drawing context
  var ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fillStyle = 'grey';
  ctx.fill();
  ctx.fillStyle = 'white';
  ctx.font = 'bold 25px Arial';
  ctx.textAlign = 'center';
  ctx.fillText(initials, radius + 5, (radius * 4) / 3 + margin);
  return canvas.toDataURL();
};

/**
 * Function to get src for image based on the user info object passed
 *
 * @param {*} userInfo
 * @returns
 */
export const getImage = (userInfo, size = 'small') => {
  if (userInfo.new_avatar_id != null) {
    if (userInfo.new_avatar_id && parseInt(userInfo.new_avatar_id, 10) > 0) {
      return getV1Image(userInfo.new_avatar_id, size);
    } else {
      return generateAvatar(userInfo.name);
    }
  } else {
    if (userInfo.avatar && userInfo.avatar.length > 5) {
      return getV0Image(userInfo.avatar);
    } else {
      return generateAvatar(userInfo.name);
    }
  }
};

/**
 * Function to get src for image based on the user info object passed
 *
 * @param {*} userInfo
 * @returns
 */
export const getUserCover = (userInfo, size = 'large') => {
  if (userInfo.cover_id != null && false) {
    // so far all cover photos are old types
    console.log('getUserCover exists', getV1Image(userInfo.cover_id, size));
    return getV1Image(userInfo.cover_id, size);
  } else {
    console.log('getUserCover null', getV0Image(userInfo.cover));
    return getV0Image(userInfo.cover);
  }
};

/**
 * Function to get src for image based on the tesm info object passed
 *
 * @param {*} teamInfo
 * @returns
 */
export const getTeamAvatar = (teamInfo, size = 'small') => {
  if (teamInfo.new_avatar_id != null) {
    if (teamInfo.new_avatar_id && parseInt(teamInfo.new_avatar_id, 10) > 0) {
      return getV1Image(teamInfo.new_avatar_id, size);
    } else {
      return generateAvatar(teamInfo.name);
    }
  } else {
    if (teamInfo.avatar && teamInfo.avatar.length > 5) {
      return getV0Image(teamInfo.avatar);
    } else {
      return generateAvatar(teamInfo.name);
    }
  }
};
/**
 * Function to get src for image based on the inst info object passed
 *
 * @param {*} instInfo
 * @returns
 */
export const getInstEmblem = (instInfo, size = 'small') => {
  if (instInfo.new_emblem_id != null) {
    if (instInfo.new_emblem_id && parseInt(instInfo.new_emblem_id, 10) > 0) {
      return getV1Image(instInfo.new_emblem_id, size);
    } else {
      return generateAvatar(instInfo.name);
    }
  } else {
    if (instInfo.emblem && instInfo.emblem.length > 5) {
      return getV0Image(instInfo.emblem);
    } else {
      return generateAvatar(instInfo.name);
    }
  }
};

export const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const mimeFromDataUrl = (dataUrl) =>
  dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(';'));

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const sizeFromURI = (dataURI) => {
  let base64Length = dataURI.length - (dataURI.indexOf(',') + 1);
  let padding =
    dataURI.charAt(dataURI.length - 2) === '='
      ? 2
      : dataURI.charAt(dataURI.length - 1) === '='
      ? 1
      : 0;
  return base64Length * 0.75 - padding;
};

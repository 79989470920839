import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import * as Sentry from '@sentry/react';
//import Integrations from '@sentry/tracing/Integrations';

//import './index.css';
import { store, persistedStore } from './store';

import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
//import reportWebVitals from './reportWebVitals';
//import sendToAnalytics from './utils/analytics';

//gt_config.environment === "prod" && (console.log = console.warn = console.error = () => {});
//gt_config.environment === "stage" && (console.warn = () => {});

/*Sentry.init({
  dsn: 'https://08380d405d3747dbafde4608cf1913c0@o230345.ingest.sentry.io/1388847',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});*/

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  rootElement,
);

// https://github.com/the-road-to-react-with-firebase/react-redux-firebase-authentication/blob/master/src/components/App/index.js

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(sendToAnalytics);

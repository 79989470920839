import {
  unauthGetRequest,
  unauthPostJSONRequest,
  getRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';
import TokenManager from '../../utils/tokenManager';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_FB_FAILURE = 'LOGIN_FB_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const AUTH_TYPE = 'AUTH_TYPE';

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (tokens) => {
  return {
    type: LOGIN_SUCCESS,
    payload: tokens,
  };
};

const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

const loginFBError = (error) => {
  return {
    type: LOGIN_FB_FAILURE,
    payload: error,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const destroySession = () => {
  return {
    type: DESTROY_SESSION,
  };
};

const authType = (signupWith) => {
  return {
    type: AUTH_TYPE,
    payload: signupWith,
  };
};

export const loginFirebaseUser = () => async (dispatch) => {
  dispatch(requestLogin());
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.auth}firebase/${TokenManager.getIdToken()}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status && json.data && json.data.accessToken) {
      TokenManager.set(json.data.accessToken, json.data.refreshToken);
      const jwtRequest = new Request(
        `${gt_config.host}auth/jwt/?jwt=${TokenManager.get()}`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      );
      await fetch(jwtRequest);
      return dispatch(receiveLogin(json.data));
    } else {
      return dispatch(loginFBError(json.error));
    }
  } catch (error) {
    return dispatch(loginFBError(error));
  }
};

export const loginUser = (email, mobile, password) => async (dispatch) => {
  TokenManager.remove();
  deleteCookies();

  dispatch(requestLogin());
  try {
    let obj = email ? { email, password } : { mobile, password };
    const response = await fetch(
      unauthPostJSONRequest(`${gt_config.urls.auth}login`, obj),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.hasOwnProperty('data') && json.data.accessToken) {
      TokenManager.set(json.data.accessToken, json.data.refreshToken);
      const jwtRequest = new Request(
        `${gt_config.host}auth/jwt/?jwt=${TokenManager.get()}`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      );
      await fetch(jwtRequest);
      dispatch(loginError(null));
      return dispatch(receiveLogin(json.data));
    } else {
      return dispatch(loginError(json.error));
    }
  } catch (error) {
    return dispatch(loginError(error));
  }
};

export const refreshToken = () => async (dispatch) => {
  if (
    TokenManager.getRefresh() === null ||
    TokenManager.getRefresh() === undefined ||
    (TokenManager.getTokenRefreshedAt() != null &&
      parseInt(TokenManager.getTokenRefreshedAt(), 10) +
        gt_config.jwtRefresh * 1000 >
        Date.now())
  )
    return true;

  try {
    const request = new Request(`${gt_config.urls.auth}refresh_token`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: 'Bearer ' + TokenManager.getRefresh(),
        'Content-Type': 'application/json',
      },
    });
    const res = await fetch(request);
    const json = await res.json();
    if (json.data && json.data.accessToken) {
      TokenManager.set(json.data.accessToken);
      const jwtRequest = new Request(
        `${gt_config.host}auth/jwt/?jwt=${TokenManager.get()}`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      );
      await fetch(jwtRequest);
      return dispatch(receiveLogin(json.data));
    } else {
      TokenManager.remove();
      deleteCookies();
      dispatch(loginError(json.error));
      return dispatch(requestLogout());
    }
  } catch (error) {
    console.error(error);
    return dispatch(requestLogout());
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(requestLogout());
  try {
    fetch(unauthGetRequest(`${gt_config.host}auth/logout/`));
    await fetch(getRequest(`${gt_config.urls.auth}logout`));
  } catch (error) {
    console.error(error);
    // do nothing
  }
  TokenManager.remove();
  deleteCookies();
  dispatch(destroySession());
  window.mobileApp && window.mobileApp.postMessage('{"action":"logout"}');
};

export const dispatchDestroySession = () => (dispatch) =>
  dispatch(destroySession());
export const dispatchAuthType = (signupWith) => (dispatch) =>
  dispatch(authType(signupWith));

export const loginOldSite = async () => {
  if (TokenManager.get()) {
    const jwtRequest = new Request(
      `${gt_config.host}auth/jwt/?jwt=${TokenManager.get()}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      },
    );
    await fetch(jwtRequest);
  }
};

const deleteCookies = () => {
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};

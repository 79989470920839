import {
  getRequest,
  postJSONRequest,
  putJSONRequest,
  anonymousGetRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

const requestUserDetails = () => {
  return {
    type: USER_DETAILS_REQUEST,
  };
};

const receiveUserDetails = (details) => {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: details,
  };
};

const userDetailsError = () => {
  return {
    type: USER_DETAILS_FAILURE,
  };
};

const requestUserUpdate = () => {
  return {
    type: USER_UPDATE_REQUEST,
  };
};

const receiveUserUpdate = (details) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: details,
  };
};

const userUpdateError = (e) => {
  return {
    type: USER_UPDATE_FAILURE,
    payload: e,
  };
};

export const fetchUserDetails = () => async (dispatch) => {
  dispatch(requestUserDetails());
  try {
    const response = await fetch(getRequest(`${gt_config.urls.user}info`));
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveUserDetails(json.data));
    else dispatch(userDetailsError(json.error || json.errors));
  } catch (error) {
    console.error(error);
    dispatch(userDetailsError());
  }
  return false;
};

export const fetchOpenUserDetailsByUris = async (user_uris) => {
  //comma separated uris, response is key value pair with uris as the key
  try {
    const response = await fetch(
      `${gt_config.urls.openuser}open/info_uri/${user_uris}`,
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenUserAgents = async (userUri) => {
  try {
    const response = await fetch(
      anonymousGetRequest(
        `${gt_config.urls.opensports}open/all_agents_uri/${userUri}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenRandomUsers = async () => {
  try {
    const response = await fetch(`${gt_config.urls.openuser}open/random_users`);
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const checkUri = async (uri) => {
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.registration}uri`, { uri }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return true;
    else return json.error;
  } catch (error) {
    console.error('checkUri', error);
    // do nothing
  }
};

export const updateUri = (uri) => async (dispatch) => {
  dispatch(requestUserUpdate());
  try {
    const response = await fetch(
      putJSONRequest(`${gt_config.urls.registration}uri`, { uri }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveUserUpdate({ uri }));
    else return dispatch(userUpdateError(json.error));
  } catch (error) {
    console.error('updateUri', error);
    return dispatch(userUpdateError(error));
  }
};

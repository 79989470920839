import { combineReducers } from 'redux';
import authReducer from './auth';
import userReducer from './user';
import registerReducer from './register';
import athleteReducer from './athlete';
import commonReducer from './common';
import settingsReducer from './settings';
import coachReducer from './coach';
import scoutReducer from './scout';
import tryoutReducer from './tryout';
import teamReducer from './team';
import instituteReducer from './institute';
import marketReducer from './market';
import scoutingProfileReducer from './scoutingProfile';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  register: registerReducer,
  common: commonReducer,
  settings: settingsReducer,
  athlete: athleteReducer,
  coach: coachReducer,
  scout: scoutReducer,
  tryout: tryoutReducer,
  team: teamReducer,
  institute: instituteReducer,
  market: marketReducer,
  scoutingProfile: scoutingProfileReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

export default rootReducer;

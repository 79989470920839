import {
  postJSONRequest,
  postJSONRequestWard,
  postJSONRequestGuardian,
  unauthGetRequest,
  unauthPostJSONRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';
import TokenManager from '../../utils/tokenManager';

export const USER_FIREBASE_REGISTER_REQUEST = 'USER_FIREBASE_REGISTER_REQUEST';
export const USER_FIREBASE_REGISTER_SUCCESS = 'USER_FIREBASE_REGISTER_SUCCESS';
export const USER_FIREBASE_REGISTER_FAILURE = 'USER_FIREBASE_REGISTER_FAILURE';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const GUARDIAN_REGISTER_REQUEST = 'GUARDIAN_REGISTER_REQUEST';
export const GUARDIAN_REGISTER_SUCCESS = 'GUARDIAN_REGISTER_SUCCESS';

export const WARD_REGISTER_REQUEST = 'WARD_REGISTER_REQUEST';
export const WARD_REGISTER_SUCCESS = 'WARD_REGISTER_SUCCESS';

export const USER_SIGNUP_OTP_VERIFY_REQUEST = 'USER_SIGNUP_OTP_VERIFY_REQUEST';
export const USER_SIGNUP_OTP_VERIFY_SUCCESS = 'USER_SIGNUP_OTP_VERIFY_SUCCESS';
export const USER_SIGNUP_OTP_VERIFY_FAILURE = 'USER_SIGNUP_OTP_VERIFY_FAILURE';

export const USER_ONBOARDBASIC_REQUEST = 'USER_ONBOARDBASIC_REQUEST';
export const USER_ONBOARDBASIC_SUCCESS = 'USER_ONBOARDBASIC_SUCCESS';
export const USER_ONBOARDBASIC_FAILURE = 'USER_ONBOARDBASIC_FAILURE';

export const NON_DEFAULT_USER_ONBOARDBASIC_REQUEST =
  'NON_DEFAULT_USER_ONBOARDBASIC_REQUEST';
export const NON_DEFAULT_USER_ONBOARDBASIC_SUCCESS =
  'NON_DEFAULT_USER_ONBOARDBASIC_SUCCESS';
export const NON_DEFAULT_USER_ONBOARDBASIC_FAILURE =
  'NON_DEFAULT_USER_ONBOARDBASIC_FAILURE';

export const USER_OCCUPATION_CHANGE_REQUEST = 'USER_OCCUPATION_CHANGE_REQUEST';
export const USER_OCCUPATION_CHANGE_SUCCESS = 'USER_OCCUPATION_CHANGE_SUCCESS';
export const USER_OCCUPATION_CHANGE_FAILURE = 'USER_OCCUPATION_CHANGE_FAILURE';

export const USER_ONBOARDING_CHOICES = 'USER_ONBOARDING_CHOICES';

export const USER_SIGNUP_RESEND_OTP = 'USER_SIGNUP_RESEND_OTP';
export const USER_SIGNUP_SEND_OTP = 'USER_SIGNUP_SEND_OTP';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_OCCUPATION = 'USER_OCCUPATION';

export const REGISTER_TYPE = 'REGISTER_TYPE';
export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_END = 'REGISTRATION_END';

const receiveLogin = (tokens) => {
  return {
    type: LOGIN_SUCCESS,
    payload: tokens,
  };
};

const requestFirebaseSignup = () => {
  return {
    type: USER_FIREBASE_REGISTER_REQUEST,
  };
};

const receiveFirebaseSignup = () => {
  return {
    type: USER_FIREBASE_REGISTER_SUCCESS,
  };
};

const firebaseSignupError = (error) => {
  return {
    type: USER_FIREBASE_REGISTER_FAILURE,
    payload: error,
  };
};

const requestSignup = () => {
  return {
    type: USER_REGISTER_REQUEST,
  };
};

const receiveSignup = (email) => {
  return {
    type: USER_REGISTER_SUCCESS,
    payload: { email },
  };
};

const receiveSignupMobile = (mobile) => {
  return {
    type: USER_REGISTER_SUCCESS,
    payload: { mobile },
  };
};

const requestGuardianSignup = () => {
  return {
    type: GUARDIAN_REGISTER_REQUEST,
  };
};

const receiveGuardianSignup = (email) => {
  return {
    type: GUARDIAN_REGISTER_SUCCESS,
    payload: { email },
  };
};

const receiveGuardianSignupMobile = (mobile) => {
  return {
    type: GUARDIAN_REGISTER_SUCCESS,
    payload: { mobile },
  };
};

const requestWardSignup = () => {
  return {
    type: WARD_REGISTER_REQUEST,
  };
};

const receiveWardSignup = (email) => {
  return {
    type: WARD_REGISTER_SUCCESS,
    payload: { email },
  };
};

const receiveWardSignupMobile = (mobile) => {
  return {
    type: WARD_REGISTER_SUCCESS,
    payload: { mobile },
  };
};

const signupError = (error) => {
  return {
    type: USER_REGISTER_FAILURE,
    payload: error,
  };
};

const resetSignupError = () => {
  return {
    type: USER_REGISTER_FAILURE,
    payload: null,
  };
};

const requestSignupVerifyOTP = () => {
  return {
    type: USER_SIGNUP_OTP_VERIFY_REQUEST,
  };
};

const receiveSignupVerifyOTP = (msg) => {
  return {
    type: USER_SIGNUP_OTP_VERIFY_SUCCESS,
  };
};

const signupVerifyOTPFailure = (error) => {
  return {
    type: USER_SIGNUP_OTP_VERIFY_SUCCESS,
    payload: error,
  };
};

const receiveResendSignupOTP = () => {
  return {
    type: USER_SIGNUP_RESEND_OTP,
    payload: true,
  };
};

const receiveSendSignupOTP = () => {
  return {
    type: USER_SIGNUP_SEND_OTP,
    payload: true,
  };
};

const requestOnBoardBasic = () => {
  return {
    type: USER_ONBOARDBASIC_REQUEST,
  };
};

const receiveOnBoardBasic = (sport_id) => {
  return {
    type: USER_ONBOARDBASIC_SUCCESS,
    payload: sport_id,
  };
};

const onBoardBasicError = (error) => {
  return {
    type: USER_ONBOARDBASIC_FAILURE,
    payload: error,
  };
};

const requestOnBoardNonDefaultUserBasic = () => {
  return {
    type: NON_DEFAULT_USER_ONBOARDBASIC_REQUEST,
  };
};

const receiveOnBoardNonDefaultUserBasic = () => {
  return {
    type: NON_DEFAULT_USER_ONBOARDBASIC_SUCCESS,
  };
};

const onBoardBasicNonDefaultUserError = (error) => {
  return {
    type: NON_DEFAULT_USER_ONBOARDBASIC_FAILURE,
    payload: error,
  };
};

const setOccupation = (occupation_id) => {
  return {
    type: USER_OCCUPATION,
    payload: occupation_id,
  };
};

const requestChangeOccupation = () => {
  return {
    type: USER_OCCUPATION_CHANGE_REQUEST,
  };
};

const receiveChangeOccupation = (occupation_id) => {
  return {
    type: USER_OCCUPATION_CHANGE_SUCCESS,
    payload: occupation_id,
  };
};

const occupationError = (error) => {
  return {
    type: USER_OCCUPATION_CHANGE_FAILURE,
    payload: error,
  };
};

const updateRegistrationChoices = (data) => {
  return {
    type: USER_ONBOARDING_CHOICES,
    payload: data,
  };
};

const registrationType = (signupWith) => {
  return {
    type: REGISTER_TYPE,
    payload: signupWith,
  };
};

const registrationStart = () => {
  return {
    type: REGISTRATION_START,
  };
};

const registrationEnd = () => {
  return {
    type: REGISTRATION_END,
  };
};

export const signupFirebaseUser = (referer) => async (dispatch) => {
  dispatch(requestFirebaseSignup());
  try {
    const response = await fetch(
      unauthPostJSONRequest(`${gt_config.urls.registration}register_firebase`, {
        id_token: TokenManager.getIdToken(),
        referer: referer,
      }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status && json.data) {
      TokenManager.set(json.data.accessToken, json.data.refreshToken);
      dispatch(receiveFirebaseSignup());
      return dispatch(receiveLogin(json.data));
    } else {
      dispatch(firebaseSignupError(json.error));
      return json.error;
    }
  } catch (error) {
    console.error(error);
    dispatch(firebaseSignupError(error));
    return error;
  }
};

export const dispatchSetOccupation = (occupation_id) => (dispatch) =>
  dispatch(setOccupation(occupation_id));

export const signupUser =
  (firstname, lastname, email, password, referer) => async (dispatch) => {
    dispatch(requestSignup());
    try {
      const response = await fetch(
        unauthPostJSONRequest(`${gt_config.urls.registration}register`, {
          firstname,
          lastname,
          email,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveSignup(email));
      } else {
        if (
          json.errors.email === 'REGISTRATION_EMAIL_ALREADY_EXISTS_INACTIVE'
        ) {
          dispatch(signupError(json.errors));
          return dispatch(receiveSignup(email));
        }
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupUserWithMobile =
  (firstname, lastname, mobile, password, referer) => async (dispatch) => {
    dispatch(requestSignup());
    try {
      const response = await fetch(
        unauthPostJSONRequest(`${gt_config.urls.registration}register`, {
          firstname,
          lastname,
          mobile,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveSignupMobile(mobile));
      } else {
        if (
          json.errors.mobile === 'REGISTRATION_MOBILE_ALREADY_EXISTS_INACTIVE'
        ) {
          dispatch(signupError(json.errors));
          return dispatch(receiveSignupMobile(mobile));
        }
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupUserGuardian =
  (firstname, lastname, email, password, referer) => async (dispatch) => {
    dispatch(requestGuardianSignup());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}register/guardian`, {
          firstname,
          lastname,
          email,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveGuardianSignup(email));
      } else {
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupUserWard =
  (firstname, lastname, email, password, referer) => async (dispatch) => {
    dispatch(requestWardSignup());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}register/ward`, {
          firstname,
          lastname,
          email,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveWardSignup(email));
      } else {
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupUserGuardianMobile =
  (firstname, lastname, mobile, password, referer) => async (dispatch) => {
    dispatch(requestGuardianSignup());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}register/guardian`, {
          firstname,
          lastname,
          mobile,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveGuardianSignupMobile(mobile));
      } else {
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupUserWardMobile =
  (firstname, lastname, mobile, password, referer) => async (dispatch) => {
    dispatch(requestWardSignup());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}register/ward`, {
          firstname,
          lastname,
          mobile,
          password,
          referer: referer,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveWardSignupMobile(mobile));
      } else {
        return dispatch(signupError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const signupVerifyOTP = (email, otp) => async (dispatch) => {
  dispatch(requestSignupVerifyOTP());
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.registration}validate_otp/${email}/${otp}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      TokenManager.set(json.data.accessToken, json.data.refreshToken);
      dispatch(receiveSignupVerifyOTP());
      return dispatch(receiveLogin(json.data));
    } else {
      return dispatch(signupError(json.error || 'Incorrect code'));
    }
  } catch (error) {
    console.error(error);
    return dispatch(signupError(error));
  }
};

export const signupMobileVerifyOTP = (mobile, otp) => async (dispatch) => {
  dispatch(requestSignupVerifyOTP());
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.registration}validate_otp_mobile/${mobile}/${otp}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      TokenManager.set(json.data.accessToken, json.data.refreshToken);
      dispatch(receiveSignupVerifyOTP());
      return dispatch(receiveLogin(json.data));
    } else {
      return dispatch(signupError(json.error || 'Incorrect code'));
    }
  } catch (error) {
    console.error(error);
    return dispatch(signupError(error));
  }
};

export const guardianVerifyOTP =
  (email, otp, existingIsGuardian = true) =>
  async (dispatch) => {
    dispatch(requestSignupVerifyOTP());
    try {
      const response = await fetch(
        unauthGetRequest(
          `${gt_config.urls.registration}validate_otp/${email}/${otp}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        if (existingIsGuardian) {
          const existingJwt = TokenManager.get();
          const existingRefresh = TokenManager.getRefresh();
          //TokenManager.set(json.data.accessToken, json.data.refreshToken)
          TokenManager.setGuardian(existingJwt, existingRefresh);
          TokenManager.setWard(
            json.data.accessToken,
            json.data.refreshToken,
            email,
          );
        } else {
          const existingJwt = TokenManager.get();
          const existingRefresh = TokenManager.getRefresh();
          TokenManager.setGuardian(
            json.data.accessToken,
            json.data.refreshToken,
          );
          TokenManager.setWard(existingJwt, existingRefresh, email);
        }
        return dispatch(receiveSignupVerifyOTP());
      } else {
        return dispatch(signupError(json.error || 'Incorrect code'));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const guardianMobileVerifyOTP =
  (mobile, otp, existingIsGuardian = true) =>
  async (dispatch) => {
    dispatch(requestSignupVerifyOTP());
    try {
      const response = await fetch(
        unauthGetRequest(
          `${gt_config.urls.registration}validate_otp_mobile/${mobile}/${otp}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        if (existingIsGuardian) {
          const existingJwt = TokenManager.get();
          const existingRefresh = TokenManager.getRefresh();
          //TokenManager.set(json.data.accessToken, json.data.refreshToken)
          TokenManager.setGuardian(existingJwt, existingRefresh);
          TokenManager.setWard(
            json.data.accessToken,
            json.data.refreshToken,
            mobile,
          );
        } else {
          const existingJwt = TokenManager.get();
          const existingRefresh = TokenManager.getRefresh();
          TokenManager.setGuardian(
            json.data.accessToken,
            json.data.refreshToken,
          );
          TokenManager.setWard(existingJwt, existingRefresh, mobile);
        }
        return dispatch(receiveSignupVerifyOTP());
      } else {
        return dispatch(signupError(json.error || 'Incorrect code'));
      }
    } catch (error) {
      console.error(error);
      return dispatch(signupError(error));
    }
  };

export const sendSignupOTP = (email) => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(`${gt_config.urls.registration}send_otp_email/${email}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      dispatch(receiveSignup(email));
      return dispatch(receiveSendSignupOTP());
    }
  } catch (error) {
    console.error(error);
    // do nothing
  }
};

export const resendSignupOTP = (email) => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.registration}resend_otp_email/${email}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      return dispatch(receiveResendSignupOTP());
    }
  } catch (error) {
    console.error(error);
    // do nothing
  }
};

export const sendSignupMobileOTP = (mobile) => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.registration}resend_otp_mobile/${mobile}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      dispatch(receiveSignup(mobile));
      return dispatch(receiveSendSignupOTP());
    }
  } catch (error) {
    console.error(error);
    // do nothing
  }
};

export const resendSignupMobileOTP = (mobile) => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.urls.registration}resend_otp_mobile/${mobile}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      return dispatch(receiveResendSignupOTP());
    }
  } catch (error) {
    console.error(error);
    // do nothing
  }
};

export const userOccupation = (occupation_id) => async (dispatch) => {
  dispatch(requestChangeOccupation());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.registration}onboard/occupation`, {
        occupation: occupation_id,
      }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) {
      return dispatch(receiveChangeOccupation(occupation_id));
    } else {
      return dispatch(occupationError(json.errors));
    }
  } catch (error) {
    console.error(error);
    return dispatch(occupationError());
  }
};

export const userOnBoardBasic =
  (country, geoname_id, avatar_id, gender, bio, lang, sport_id, dob) =>
  async (dispatch) => {
    dispatch(requestOnBoardBasic());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}onboard/basic`, {
          country,
          geoname_id,
          avatar_id,
          gender,
          bio,
          lang,
          sport_id,
          dob,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) {
        return dispatch(receiveOnBoardBasic(sport_id));
      } else {
        return dispatch(onBoardBasicError(json.errors));
      }
    } catch (error) {
      console.error(error);
      return dispatch(onBoardBasicError());
    }
  };

export const nonDefaultUserOnBoardBasic =
  (
    country,
    geoname_id,
    avatar_id,
    gender,
    bio,
    lang,
    sport_id,
    dob,
    existingIsGuardian = false,
    email = null,
  ) =>
  async (dispatch) => {
    dispatch(requestOnBoardNonDefaultUserBasic());
    try {
      if (existingIsGuardian) {
        // this is the ward we are submitting for
        const response = await fetch(
          postJSONRequestWard(
            email,
            `${gt_config.urls.registration}onboard/basic`,
            {
              country,
              geoname_id,
              avatar_id,
              gender,
              bio,
              lang,
              sport_id,
              dob,
            },
          ),
        );
        const res = await handleAPIError(response);
        const json = await res.json();
        if (json.status) {
          return dispatch(receiveOnBoardNonDefaultUserBasic(sport_id));
        } else {
          return dispatch(onBoardBasicNonDefaultUserError(json.errors));
        }
      } else {
        const response = await fetch(
          postJSONRequestGuardian(
            `${gt_config.urls.registration}onboard/basic`,
            {
              country,
              geoname_id,
              avatar_id,
              gender,
              bio,
              lang,
              sport_id,
              dob,
            },
          ),
        );
        const res = await handleAPIError(response);
        const json = await res.json();
        if (json.status) {
          return dispatch(receiveOnBoardNonDefaultUserBasic());
        } else {
          return dispatch(onBoardBasicNonDefaultUserError(json.errors));
        }
      }
    } catch (error) {
      console.error(error);
      return dispatch(onBoardBasicError());
    }
  };

export const updateUserRegistrationChoices = (choice) => (dispatch) => {
  dispatch(updateRegistrationChoices(choice));
};

export const resetErrorsDispatch = () => (dispatch) => {
  return dispatch(resetSignupError());
};

export const dispatchRegisterType = (signupWith) => (dispatch) => {
  return dispatch(registrationType(signupWith));
};

export const dispatchRegistrationStart = () => (dispatch) => {
  return dispatch(registrationStart());
};

export const dispatchRegistrationEnd = () => (dispatch) => {
  return dispatch(registrationEnd());
};

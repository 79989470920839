import {
  SCOUT_PROFILE_SUCCESS,
  SCOUT_PROFILE_ADD_SUCCESS,
} from '../actions/scout';

const initialState = {
  info: null,
  lastAddedScoutId: null,
};

export const scoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCOUT_PROFILE_SUCCESS:
      const updatedScoutsInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedScoutsInfo });
    case SCOUT_PROFILE_ADD_SUCCESS:
      const scoutId = action.payload['scout'];
      const updatedInfo = { ...state.info, ...{ [scoutId]: action.payload } };
      return Object.assign({}, state, {
        info: updatedInfo,
        lastAddedScoutId: scoutId,
      });
    default:
      return state;
  }
};

export default scoutReducer;

import TokenManager from './tokenManager';

export const unauthGetRequest = (url) => {
  return new Request(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
};

export const unauthPostJSONRequest = (url, data) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(data),
  });
};

export const getRequest = (url) => {
  return new Request(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
  });
};

export const anonymousGetRequest = (url) => {
  return new Request(url, {
    method: 'GET',
    cache: 'force-cache',
    headers: new Headers({
      Authorization: 'Bearer anonymous',
    }),
    credentials: 'omit',
  });
};

export const deleteRequest = (url) => {
  return new Request(url, {
    method: 'DELETE',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
  });
};

export const postJSONRequest = (url, data) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const putJSONRequest = (url, data) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const postFormRequest = (url, formData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const putFormRequest = (url, formData) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.get(),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const postBlobRequest = (url, blobData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.get(),
      'Content-Type': 'application/octet-stream',
    }),
    credentials: 'omit',
    body: blobData,
  });
};

/** All Ward specific requests */
export const getRequestWard = (email, url) => {
  return new Request(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getWard(email),
    }),
    credentials: 'omit',
  });
};

export const postJSONRequestWard = (email, url, data) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getWard(email),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const putJSONRequestWard = (email, url, data) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getWard(email),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const postFormRequestWard = (email, url, formData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getWard(email),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const putFormRequestWard = (email, url, formData) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getWard(email),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const postBlobRequestWard = (email, url, formData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getWard(email),
      'Content-Type': 'application/octet-stream',
    }),
    credentials: 'omit',
    body: formData,
  });
};

/** All Guardian specific requests */
export const getRequestGuardian = (url) => {
  return new Request(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
    }),
    credentials: 'omit',
  });
};

export const postJSONRequestGuardian = (url, data) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const putJSONRequestGuardian = (url, data) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
    }),
    credentials: 'omit',
    body: JSON.stringify(data),
  });
};

export const postFormRequestGuardian = (url, formData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const putFormRequestGuardian = (url, formData) => {
  return new Request(url, {
    method: 'PUT',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
    }),
    credentials: 'omit',
    body: formData,
  });
};

export const postBlobRequestGuardian = (url, formData) => {
  return new Request(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: new Headers({
      Authorization: 'Bearer ' + TokenManager.getGuardian(),
      'Content-Type': 'application/octet-stream',
    }),
    credentials: 'omit',
    body: formData,
  });
};

import {
  unauthGetRequest,
  getRequest,
  postBlobRequest,
  postJSONRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';
import {
  mimeFromDataUrl,
  dataURItoBlob,
  sizeFromURI,
} from '../../utils/images';

export const COMMON_REQUEST = 'COMMON_REQUEST';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';
export const COMMON_FAILURE = 'COMMON_FAILURE';

export const commonRequest = () => {
  return {
    type: COMMON_REQUEST,
  };
};

export const commonSuccess = () => {
  return {
    type: COMMON_SUCCESS,
  };
};

export const commonError = (error) => {
  return {
    type: COMMON_FAILURE,
    payload: error,
  };
};

export const FETCH_INIT_VARS = 'FETCH_INIT_VARS';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_LANG = 'FETCH_LANG';
export const FETCH_SPORTS = 'FETCH_SPORTS';
export const SET_REFERRER = 'SET_REFERRER';
export const SET_NOBUTTON = 'SET_NOBUTTON';
export const SET_NOHEADER = 'SET_NOHEADER';
export const SET_PAGETITLE = 'SET_PAGETITLE';
export const SET_PAGEMETATAGS = 'SET_PAGEMETATAGS';
export const SET_PAGELINKS = 'SET_PAGELINKS';

const receiveInitVars = (vars) => {
  return {
    type: FETCH_INIT_VARS,
    payload: vars,
  };
};

const receiveCountries = (countries) => {
  return {
    type: FETCH_COUNTRIES,
    payload: countries,
  };
};

const receiveLangs = (langs) => {
  return {
    type: FETCH_LANG,
    payload: langs,
  };
};

const receiveSports = (sports) => {
  return {
    type: FETCH_SPORTS,
    payload: sports,
  };
};

const receiveReferrer = (referrer) => {
  return {
    type: SET_REFERRER,
    payload: referrer,
  };
};

const receiveNoButton = (noButton) => {
  return {
    type: SET_NOBUTTON,
    payload: noButton,
  };
};

const receiveNoHeader = (noHeader) => {
  return {
    type: SET_NOHEADER,
    payload: noHeader,
  };
};

const receivePageTitle = (title) => {
  return {
    type: SET_PAGETITLE,
    payload: title,
  };
};

const receivePageMetaTags = (metaTags) => {
  return {
    type: SET_PAGEMETATAGS,
    payload: metaTags,
  };
};

const receivePageLinks = (links) => {
  return {
    type: SET_PAGELINKS,
    payload: links,
  };
};

export const fetchInitVars = () => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(`${gt_config.urls.registration}init_vars`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveInitVars(json.data));
  } catch (error) {
    // do nothing
  }
};

export const fetchCountries = () => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_countries`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data) return dispatch(receiveCountries(json.data));
  } catch (error) {
    // do nothing
  }
};

export const fetchStates = async (country_name) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_states&country_name=${country_name}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
  }
};

export const fetchCities = async (country_name, state_name) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_cities&country_name=${country_name}&state_name=${state_name}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const autoSuggestCities = async (city_name) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=city_qs&q=${city_name}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const fetchSports = () => async (dispatch) => {
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}all_sports`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data) {
      const sortedData = [];
      Object.keys(json.data).forEach((k) => {
        sortedData.push({ ...json.data[k], ...{ sportId: parseInt(k) } });
      });
      sortedData.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        else if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        else return 0;
      });
      return dispatch(receiveSports(sortedData));
    }
  } catch (error) {
    // do nothing
    console.error(error);
    console.log(error);
  }
};

export const fetchLangs = () => async (dispatch) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_locales`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data) return dispatch(receiveLangs(json.data));
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const uploadFile = async (file) => {
  try {
    let success = false;
    if (file.size < 3000000 && false) {
      success = uploadSmallFile(file);
    }

    if (success !== false) return success;

    let firstUrl = `${gt_config.urls.media}s3_upload_url/${
      file.name
    }/${encodeURIComponent(file.type)}`;
    if (file.type.indexOf('image') === 0) {
      firstUrl = `${gt_config.urls.media}s3_upload_url_image/${
        file.name
      }/${encodeURIComponent(file.type)}`;
    }
    let response = await fetch(getRequest(firstUrl));
    let res = await handleAPIError(response);
    let json = await res.json();

    const newUrl = json.data.url;

    const formData = new FormData();
    Object.keys(json.data.fields).forEach((key) => {
      formData.append(key, json.data.fields[key]);
    });
    formData.append('file', file);

    const request = new Request(newUrl, {
      method: 'POST',
      body: formData,
    });

    response = await fetch(request);

    let attachUrl = `${gt_config.urls.media}documents/${json.data.generated_file_name}`;
    if (file.type.indexOf('image') === 0) {
      attachUrl = `${gt_config.urls.media}images/${json.data.generated_file_name}`;
    }

    const newObj = {
      file_name: file.name,
      new_file_name: json.data.generated_file_name,
      url: attachUrl,
      mime_type: file.type,
      size: file.size,
    };

    response = await fetch(
      postJSONRequest(`${gt_config.urls.media}s3_upload_attach`, newObj),
    );
    res = await handleAPIError(response);
    json = await res.json();

    if (json.status === true) {
      return json;
    }
  } catch (error) {
    // do nothing
    console.error(error);
  }
  return false;
};

export const uploadSmallFile = async (file) => {
  if (typeof file === 'undefined') return;

  try {
    const blob = await file.arrayBuffer();
    const url = `${gt_config.urls.media}upload/${file.name}`;

    const response = await fetch(postBlobRequest(url, blob));
    const res = await handleAPIError(response);
    const json = await res.json();

    if (json.status === true) return json;

    return false;
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const uploadDataURIFile = async (file, name) => {
  /** file is data uri */
  try {
    let success = false;
    if (sizeFromURI(file) < 3000000) {
      success = uploadSmallDataURIFile(file);
    }

    if (success !== false) return success;

    let firstUrl = `${
      gt_config.urls.media
    }s3_upload_url/${name}/${encodeURIComponent(mimeFromDataUrl(file))}`;
    if (mimeFromDataUrl(file).indexOf('image') === 0) {
      firstUrl = `${
        gt_config.urls.media
      }s3_upload_url_image/${name}/${encodeURIComponent(
        mimeFromDataUrl(file),
      )}`;
    }
    let response = await fetch(getRequest(firstUrl));
    let res = await handleAPIError(response);
    let json = await res.json();

    const newUrl = json.data.url;

    const formData = new FormData();
    Object.keys(json.data.fields).forEach((key) => {
      formData.append(key, json.data.fields[key]);
    });
    formData.append('file', file);

    const request = new Request(newUrl, {
      method: 'POST',
      body: formData,
    });

    response = await fetch(request);

    let attachUrl = `${gt_config.urls.media}documents/${json.data.generated_file_name}`;
    if (mimeFromDataUrl(file).indexOf('image') === 0) {
      attachUrl = `${gt_config.urls.media}images/${json.data.generated_file_name}`;
    }

    const newObj = {
      file_name: name,
      new_file_name: json.data.generated_file_name,
      url: attachUrl,
      mime_type: mimeFromDataUrl(file),
      size: sizeFromURI(file),
    };

    response = await fetch(
      postJSONRequest(`${gt_config.urls.media}s3_upload_attach`, newObj),
    );
    res = await handleAPIError(response);
    json = await res.json();

    if (json.status === true) {
      return json;
    }
  } catch (error) {
    // do nothing
    console.error(error);
  }
  return false;
};

export const uploadSmallDataURIFile = async (file, name) => {
  if (typeof file === 'undefined') return;

  try {
    const blob = dataURItoBlob(file);
    const url = `${gt_config.urls.media}upload/${name}`;

    const response = await fetch(postBlobRequest(url, blob));
    const res = await handleAPIError(response);
    const json = await res.json();

    if (json.status === true) return json;

    return false;
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const setReferrer = (referrer) => async (dispatch) => {
  return dispatch(receiveReferrer(referrer));
};

export const searchTeams = async (qs) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_teams&q=${qs}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
  }
};

export const searchInstitutes = async (qs) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=get_insts&q=${qs}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const searchUsers = async (qs) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${gt_config.host}auth/openapis/index.php?action=users_qs&q=${qs}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const searchTournaments = async (qs) => {
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}auto_suggest/tournament/${qs}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const searchArenas = async (qs) => {
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}auto_suggest/arena/${qs}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

//TODO - move to its own reducer
export const getInstCalendarFutureEvents = async (instId, userId) => {
  try {
    const response = await fetch(
      unauthGetRequest(
        `${
          gt_config.host
        }user/calendar/actions.php?action=get_inst_events&inst_id=${instId}&user_id=${userId}&user_tz=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return json.data;
  } catch (error) {
    // do nothing
    console.error(error);
  }
};

export const accessLog = async (
  userId,
  logType = 'page_view',
  httpMethod = 'GET',
  previousUrl = null,
  extraInfo = null,
) => {
  if (userId === null) {
    return null;
  }
  if (typeof logType != 'string' || logType === null || logType.length === 0) {
    return null;
  }
  if (
    typeof httpMethod != 'string' ||
    httpMethod === null ||
    httpMethod.length === 0
  ) {
    return null;
  }
  try {
    const prevUrl = previousUrl
      ? previousUrl
      : window.document.referrer.length > 0
      ? new URL(window.document.referrer).pathname
      : '';
    const dataObject = {
      userId,
      logType,
      httpMethod,
      url: window.location.pathname,
      previousUrl: prevUrl,
      device: window.mobileApp ? 'mobile' : 'desktop',
      extraInfo,
    };
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.analytics}add`, dataObject),
    );
    const res = await handleAPIError(response);
    const json = await res.json();

    if (json.status === true) {
      return json;
    }
  } catch (error) {
    // do nothing
    console.error(error);
  }
  return false;
};
//window.GT_Analytics.post(window.current_user.id, 'page_view', 'GET', null)

export const dispatchNoButton = (val) => (dispatch) =>
  dispatch(receiveNoButton(val));
export const dispatchNoHeader = (val) => (dispatch) =>
  dispatch(receiveNoHeader(val));
export const dispatchPageTitle = (val) => (dispatch) =>
  dispatch(receivePageTitle(val));
export const dispatchPageMetaTags = (val) => (dispatch) =>
  dispatch(receivePageMetaTags(val));
export const dispatchPageLinks = (val) => (dispatch) =>
  dispatch(receivePageLinks(val));

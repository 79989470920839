import {
  INSTITUTE_DETAILS_SUCCESS,
  INSTITUTE_ADD_SUCCESS,
  ROLES_SUCCESS,
  TYPES_SUCCESS,
  INSTITUTE_UPDATE_SUCCESS,
  INSTITUTE_MEMBERS_ADD_SUCCESS,
  INSTITUTE_MEMBERS_DEL_SUCCESS,
  INSTITUTE_SCOUTS_ADD_SUCCESS,
  INSTITUTE_COACHES_SUCCESS,
  INSTITUTE_SCOUTS_SUCCESS,
} from '../actions/institute';

const initialState = {
  info: null,
  roles: {},
  types: {},
  coaches: {},
  scouts: {},
  lastAddedInstituteId: null,
};

export const instituteReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSTITUTE_DETAILS_SUCCESS:
      const updatedInstsInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedInstsInfo });
    case INSTITUTE_ADD_SUCCESS:
      const instituteId = action.payload['id'];
      const updatedInfo = {
        ...state.info,
        ...{ [instituteId]: action.payload },
      };
      return Object.assign({}, state, {
        info: updatedInfo,
        lastAddedInstituteId: instituteId,
      });
    case INSTITUTE_UPDATE_SUCCESS:
      const updatedInstituteInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedInstituteInfo });
    case INSTITUTE_SCOUTS_ADD_SUCCESS:
      const updatedScoutsInfo = { ...state.scouts, ...action.payload };
      return Object.assign({}, state, { scouts: updatedScoutsInfo });
    case ROLES_SUCCESS:
      return Object.assign({}, state, { roles: action.payload });
    case TYPES_SUCCESS:
      return Object.assign({}, state, { types: action.payload });
    case INSTITUTE_COACHES_SUCCESS:
      return Object.assign({}, state, { coaches: action.payload });
    case INSTITUTE_SCOUTS_SUCCESS:
      return Object.assign({}, state, { scouts: action.payload });
    case INSTITUTE_MEMBERS_ADD_SUCCESS:
    case INSTITUTE_MEMBERS_DEL_SUCCESS:
      const instituteInfo = state.info;
      instituteInfo[action.payload] = null;
      return Object.assign({}, state, { info: instituteInfo });
    default:
      return state;
  }
};

export default instituteReducer;

import { gt_config } from '../configs';
import TokenManager from './tokenManager';

export default function sendToAnalytics(metric) {
  try {
    if (TokenManager.get()) {
      const body = JSON.stringify(metric);
      const url = gt_config.urls.analytics + 'jslog';
      const req = new Request(url, {
        method: 'POST',
        cache: 'no-cache',
        keepalive: true,
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TokenManager.get(),
        }),
        credentials: 'omit',
        body,
      });
      fetch(req);
    }
  } catch (e) {
    console.error(e);
  }
}

export const LANDING = '/';

// all signup routes
export const SIGN_UP = '/signup';
export const SIGN_UP_OTP = '/signup/otp';
export const SIGN_UP_GUARDIAN = '/signup/onboard/guardian';
export const SIGN_UP_OTP_GUARDIAN = '/signup/onboard/guardian/otp';
export const SIGN_UP_ONBOARD_LANDING = '/signup/onboard/basic';
export const SIGN_UP_ONBOARD_LANDING_GUARDIAN =
  '/signup/onboard/guardian/basic';
export const SIGN_UP_ONBOARD_LANDING_FIREBASE = '/signup/onboard/fbbasic';
export const SIGN_UP_INVITES = '/signup/invite';
export const SIGN_UP_ONBOARD_BRANCH = '/signup/onboard/branch';
export const SIGN_UP_ONBOARD_BRANCH_UPDATE =
  '/signup/onboard/branch/update/:newRole?/:roleText?';

export const SIGN_IN = '/signin';

export const SIGN_OUT = '/signout';

export const HOME = '/home';

export const PASSWORD_FORGOT = '/password';
export const PASSWORD_NEW = '/password/new/:resetToken';
export const PASSWORD_MOBILE_NEW = '/password/mobile/:mobileNum';

export const PROFILE = '/profile';
export const PASSWORD_RESET = '/profile/settings/password';

export const ATHLETE = '/athlete/';
export const ATHLETE_AGENT = '/athlete/agent/';
export const ATHLETE_ACADEMIC_ADD = '/athlete/academic/add/';
export const ATHLETE_SPORT_ADD = '/athlete/sports/add/';
export const ATHLETE_AGENT_EDIT = '/athlete/agent/edit';

export const TNC = '/tnc';
export const PRIVACY = '/privacy';
export const ABOUT = '/about-us';
export const OPENSEARCH = '/open-search';

export const COACH = '/coach';
export const COACH_PROFILE_ADD = '/coach/add';
export const COACH_PROFILE_EDIT = '/coach/edit';
export const COACH_PROFILE = '/coach/:coachId';

export const SCOUT = '/scout';
export const SCOUT_PROFILE_ADD = '/scout/add';
export const SCOUT_PROFILE_EDIT = '/scout/edit/:coachId';
export const SCOUT_PROFILE = '/scout/:coachId';

export const TRYOUT = '/tryout';
export const TRYOUT_ADD = '/tryout/add/:instId';
export const TRYOUT_EDIT = '/tryout/edit/:tryoutId';
export const TRYOUT_DETAILS = '/tryout/:tryoutId';

export const TEAM = '/team';
export const TEAM_PROFILE_ADD = '/team/add';
export const TEAM_PROFILE_EDIT = '/team/edit';
export const TEAM_PROFILE = '/team/profile/:teamId';
export const TEAM_MEMBERS_ADD = '/team/members/add/:teamId';
export const TEAM_MEMBERS_EDIT = '/team/members/edit/:teamId';
export const TEAM_MEMBERS = '/team/members/:teamId';

export const INSTITUTE = '/institute';
export const INSTITUTE_PROFILE_EDIT = '/institute/edit';
export const INSTITUTE_PROFILE_ADD = '/institute/add';
export const INSTITUTE_PROFILE = '/institute/profile/:instId';
export const INSTITUTE_MEMBERS_ADD = '/institute/members/add/:instId';
export const INSTITUTE_MEMBERS_EDIT = '/institute/members/edit/:instId';
export const INSTITUTE_MEMBERS = '/institute/members/:instId';
export const INSTITUTE_SCOUTS_ADD = '/institute/scouts/add/:instId';
export const INSTITUTE_SCOUTS_EDIT = '/institute/scouts/edit/:instId';
export const INSTITUTE_SCOUTS = '/institute/scouts/:instId';
export const INSTITUTE_COACHES = '/institute/coaches/:instId';

export const MARKET = '/market';
export const MARKET_LANDING = '/market/landing/';
export const MARKET_SCOUT = '/market/scout/';
export const MARKET_ADVERT = '/market/advert/';
export const MARKET_AGENT = '/market/agent/';

// OPEN ACCESS URLS
export const OPEN_INFO_USER = '/info/user/:userUri';
export const OPEN_INFO_TEAM = '/info/team/:teamUri';
export const OPEN_INFO_INSTITUTE = '/info/institute/:instUri';

export const SCOUTING = '/scouting';
export const SCOUTING_PROFILE_ADD = '/scouting/add';
export const SCOUTING_PROFILE_EDIT = '/scouting/edit';
export const SCOUTING_PROFILE_TEAM_ADD = '/scouting/team/add/:teamId';
export const SCOUTING_PROFILE_INST_ADD = '/scouting/inst/add/:instId';

export const CONTRACT = '/contract';
export const CONTRACT_LANDING = '/contract/landing';
export const CONTRACT_ADD = '/contract/add';
export const CONTRACT_SHOW = '/contract/info/:contractId';
export const CONTRACT_LIST = '/contract/list/:userId';

import {
  PREFETCH_AGENTS_SUCCESS,
  PREFETCH_SCOUTS_SUCCESS,
  PREFETCH_ADVERTS_SUCCESS,
} from '../actions/market';

const initialState = {
  prefetchedAgents: [],
  prefetchedScouts: [],
  prefetchedAdverts: [],
};

export const marketReducer = (state = initialState, action) => {
  switch (action.type) {
    case PREFETCH_AGENTS_SUCCESS:
      return Object.assign({}, state, { prefetchedAgents: action.payload });
    case PREFETCH_SCOUTS_SUCCESS:
      return Object.assign({}, state, { prefetchedScouts: action.payload });
    case PREFETCH_ADVERTS_SUCCESS:
      return Object.assign({}, state, { prefetchedAdverts: action.payload });
    default:
      return state;
  }
};

export default marketReducer;

import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import reducers from './reducers'; // the value from combineReducers

export const persistConfig = {
  key: 'gt',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, reducers);
const middleware = [
  thunk,
  createLogger({ collapsed: true, duration: true }),
  //LogRocket.reduxMiddleware(),
];

export const store = createStore(pReducer, applyMiddleware(...middleware));
export const persistedStore = persistStore(store);

import common from '@mui/material/colors/common';
//import 'typeface-karla';
import { mixins, typography, components } from './common';
import { red } from '@mui/material/colors';

const defaultTheme = {
  palette: {
    primary: {
      main: red[900],
      light: common.black,
      contrastText: '#fff',
    },
    secondary: {
      main: '#1f1f33',
      contrastText: '#fff',
    },
    background: {
      paper: common.white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      white: '#fff',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: '0.04',
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: '0.08',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: '0.38',
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: '0.12',
      activatedOpacity: '0.12',
    },
  },
  typography: typography,
  mixins: mixins,
  components: components,
  breakpoints: {
    values: {
      xs: 320,
      sm: 481,
      md: 769,
      lg: 1025,
      xl: 1201,
    },
  },
};

export default defaultTheme;

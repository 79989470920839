import {
  TEAM_DETAILS_SUCCESS,
  TEAM_ADD_SUCCESS,
  SPORT_ROLES_SUCCESS,
  TEAM_UPDATE_SUCCESS,
  TEAM_MEMBERS_ADD_SUCCESS,
  TEAM_MEMBERS_DEL_SUCCESS,
} from '../actions/team';

const initialState = {
  info: null,
  roles: {},
  lastAddedTeamId: null,
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_DETAILS_SUCCESS:
      const updatedTeamsInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedTeamsInfo });
    case TEAM_ADD_SUCCESS:
      const teamId = action.payload['id'];
      const updatedInfo = { ...state.info, ...{ [teamId]: action.payload } };
      return Object.assign({}, state, {
        info: updatedInfo,
        lastAddedTeamId: teamId,
      });
    case TEAM_UPDATE_SUCCESS:
      const updatedTeamInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedTeamInfo });
    case SPORT_ROLES_SUCCESS:
      const updatedRolesInfo = { ...state.roles, ...action.payload };
      return Object.assign({}, state, { roles: updatedRolesInfo });
    case TEAM_MEMBERS_ADD_SUCCESS:
    case TEAM_MEMBERS_DEL_SUCCESS:
      const teamInfo = state.info;
      teamInfo[action.payload] = null;
      return Object.assign({}, state, { info: teamInfo });
    default:
      return state;
  }
};

export default teamReducer;

const mixins = {
  toolbar: {
    minHeight: '40px',
  },
};

//import "typeface-karla";

const typography = {
  fontFamily: [
    //'Karla',
    '-apple-system',
    'system-ui',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Ubuntu',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'sans-serif',
    'Integral-cf',
  ].join(','),
  fontSize: 12.5,
};

const components = {
  components: {
    // more components here
  },
};

export { mixins, typography, components };

import { getRequest, postJSONRequest } from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const COACH_PROFILE_ADD_REQUEST = 'COACH_PROFILE_ADD_REQUEST';
export const COACH_PROFILE_ADD_SUCCESS = 'COACH_PROFILE_ADD_SUCCESS';
export const COACH_PROFILE_ADD_FAILURE = 'COACH_PROFILE_ADD_FAILURE';
export const COACH_PROFILE_REQUEST = 'COACH_PROFILE_REQUEST';
export const COACH_PROFILE_SUCCESS = 'COACH_PROFILE_SUCCESS';
export const COACH_PROFILE_FAILURE = 'COACH_PROFILE_FAILURE';

const requestFetchProfile = () => {
  return {
    type: COACH_PROFILE_REQUEST,
  };
};

const receiveFetchProfile = (coachId, profile) => {
  return {
    type: COACH_PROFILE_SUCCESS,
    payload: { [coachId]: profile },
  };
};

const fetchProfileError = (error) => {
  return {
    type: COACH_PROFILE_FAILURE,
    payload: error,
  };
};

const requestAddProfile = () => {
  return {
    type: COACH_PROFILE_ADD_REQUEST,
  };
};

const receiveAddProfile = (profileData) => {
  return {
    type: COACH_PROFILE_ADD_SUCCESS,
    payload: profileData,
  };
};

const addCoachProfileError = (error) => {
  return {
    type: COACH_PROFILE_ADD_FAILURE,
    payload: error,
  };
};

export const addProfile = (coachId, data) => async (dispatch) => {
  dispatch(requestAddProfile());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.sports}coach_profile/${coachId}`, data),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveAddProfile(json.data));

    return dispatch(addCoachProfileError(json.msg));
  } catch (error) {
    console.error(error);
    return dispatch(addCoachProfileError(error));
  }
};

export const fetchProfile = (coachId) => async (dispatch) => {
  dispatch(requestFetchProfile());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}coach_profile/${coachId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveFetchProfile(coachId, json.data));
  } catch (error) {
    console.error(error);
    return dispatch(fetchProfileError(error));
  }
};

import Cookies from 'js-cookie';

export default class TokenManager {
  static setIdToken(idToken) {
    if (idToken) {
      localStorage.setItem('idToken', idToken);
    }
  }

  static getIdToken() {
    return localStorage.getItem('idToken');
  }

  static removeIdToken() {
    localStorage.removeItem('idToken');
  }

  static set(jwtToken, refreshToken) {
    if (jwtToken) localStorage.setItem('jwtToken', jwtToken);
    if (jwtToken) localStorage.setItem('jwt_token', jwtToken);
    if (jwtToken) localStorage.setItem('tokenRefreshedAt', Date.now());
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }

  static get() {
    if (Cookies.get('jwtToken') && localStorage.getItem('jwtToken') === null) {
      localStorage.setItem('jwtToken', Cookies.get('jwtToken'));
      localStorage.setItem('tokenRefreshedAt', Date.now());
    }
    return localStorage.getItem('jwtToken');
  }

  static getRefresh() {
    if (
      Cookies.get('refreshToken') &&
      localStorage.getItem('refreshToken') === null
    ) {
      localStorage.setItem('refreshToken', Cookies.get('refreshToken'));
    }
    return localStorage.getItem('refreshToken');
  }

  static getTokenRefreshedAt() {
    return localStorage.getItem('tokenRefreshedAt');
  }

  static setGuardian(jwtToken, refreshToken) {
    if (jwtToken) localStorage.setItem('jwtGuardianToken', jwtToken);
    if (refreshToken)
      localStorage.setItem('refreshGuardianToken', refreshToken);
  }

  static getGuardian() {
    return localStorage.getItem('jwtGuardianToken');
  }

  static getGuardianRefresh() {
    return localStorage.getItem('refreshGuardianToken');
  }

  static setGuardianLoginAsPrimary() {
    const jwtToken = TokenManager.getGuardian();
    const refreshToken = TokenManager.getGuardianRefresh();
    if (jwtToken) localStorage.setItem('jwtToken', jwtToken);
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
  }

  static setWard(jwtToken, refreshToken, identifier) {
    if (jwtToken)
      localStorage.setItem(
        'jwtWardTokens',
        Object.assign({}, localStorage.getItem('jwtWardTokens'), {
          [identifier]: jwtToken,
        }),
      );
    if (refreshToken)
      localStorage.setItem(
        'refreshWardTokens',
        Object.assign({}, localStorage.getItem('refreshWardTokens'), {
          [identifier]: refreshToken,
        }),
      );
  }

  static getWard(identifier) {
    return localStorage.getItem('jwtWardTokens')[identifier];
  }

  static getWardRefresh(identifier) {
    return localStorage.getItem('refreshWardTokens')[identifier];
  }

  static remove() {
    localStorage.removeItem('idToken');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('jwtGuardianToken');
    localStorage.removeItem('refreshGuardianToken');
    localStorage.removeItem('jwtWardTokens');
    localStorage.removeItem('refreshWardTokens');
    localStorage.clear();
  }
}

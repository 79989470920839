import {
  deleteRequest,
  getRequest,
  postJSONRequest,
  putJSONRequest,
  anonymousGetRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const INSTITUTE_DETAILS_REQUEST = 'INSTITUTE_DETAILS_REQUEST';
export const INSTITUTE_DETAILS_SUCCESS = 'INSTITUTE_DETAILS_SUCCESS';
export const INSTITUTE_DETAILS_FAILURE = 'INSTITUTE_DETAILS_FAILURE';

export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_FAILURE = 'ROLES_FAILURE';

export const TYPES_REQUEST = 'TYPES_REQUEST';
export const TYPES_SUCCESS = 'TYPES_SUCCESS';
export const TYPES_FAILURE = 'TYPES_FAILURE';

export const INSTITUTE_COACHES_REQUEST = 'INSTITUTE_COACHES_REQUEST';
export const INSTITUTE_COACHES_SUCCESS = 'INSTITUTE_COACHES_SUCCESS';
export const INSTITUTE_COACHES_FAILURE = 'INSTITUTE_COACHES_FAILURE';

export const INSTITUTE_SCOUTS_REQUEST = 'INSTITUTE_SCOUTS_REQUEST';
export const INSTITUTE_SCOUTS_SUCCESS = 'INSTITUTE_SCOUTS_SUCCESS';
export const INSTITUTE_SCOUTS_FAILURE = 'INSTITUTE_SCOUTS_FAILURE';

export const INSTITUTE_ADD_REQUEST = 'INSTITUTE_ADD_REQUEST';
export const INSTITUTE_ADD_SUCCESS = 'INSTITUTE_ADD_SUCCESS';
export const INSTITUTE_ADD_FAILURE = 'INSTITUTE_ADD_FAILURE';

export const INSTITUTE_UPDATE_REQUEST = 'INSTITUTE_UPDATE_REQUEST';
export const INSTITUTE_UPDATE_SUCCESS = 'INSTITUTE_UPDATE_SUCCESS';
export const INSTITUTE_UPDATE_FAILURE = 'INSTITUTE_UPDATE_FAILURE';

export const INSTITUTE_MEMBERS_ADD_REQUEST = 'INSTITUTE_MEMBERS_ADD_REQUEST';
export const INSTITUTE_MEMBERS_ADD_SUCCESS = 'INSTITUTE_MEMBERS_ADD_SUCCESS';
export const INSTITUTE_MEMBERS_ADD_FAILURE = 'INSTITUTE_MEMBERS_ADD_FAILURE';

export const INSTITUTE_SCOUTS_ADD_REQUEST = 'INSTITUTE_SCOUTS_ADD_REQUEST';
export const INSTITUTE_SCOUTS_ADD_SUCCESS = 'INSTITUTE_SCOUTS_ADD_SUCCESS';
export const INSTITUTE_SCOUTS_ADD_FAILURE = 'INSTITUTE_SCOUTS_ADD_FAILURE';

export const INSTITUTE_MEMBERS_DEL_REQUEST = 'INSTITUTE_MEMBERS_DEL_REQUEST';
export const INSTITUTE_MEMBERS_DEL_SUCCESS = 'INSTITUTE_MEMBERS_DEL_SUCCESS';
export const INSTITUTE_MEMBERS_DEL_FAILURE = 'INSTITUTE_MEMBERS_DEL_FAILURE';

export const INSTITUTE_MEMBERS_INV_REQUEST = 'INSTITUTE_MEMBERS_INV_REQUEST';
export const INSTITUTE_MEMBERS_INV_SUCCESS = 'INSTITUTE_MEMBERS_INV_SUCCESS';
export const INSTITUTE_MEMBERS_INV_FAILURE = 'INSTITUTE_MEMBERS_INV_FAILURE';

const requestInstituteDetails = () => {
  return {
    type: INSTITUTE_DETAILS_REQUEST,
  };
};

const receiveInstituteDetails = (details) => {
  return {
    type: INSTITUTE_DETAILS_SUCCESS,
    payload: details,
  };
};

const instituteDetailsError = () => {
  return {
    type: INSTITUTE_DETAILS_FAILURE,
  };
};

const requestRoles = () => {
  return {
    type: ROLES_REQUEST,
  };
};

const receiveRoles = (details) => {
  return {
    type: ROLES_SUCCESS,
    payload: details,
  };
};

const rolesError = () => {
  return {
    type: ROLES_FAILURE,
  };
};

const requestTypes = () => {
  return {
    type: TYPES_REQUEST,
  };
};

const receiveTypes = (details) => {
  return {
    type: TYPES_SUCCESS,
    payload: details,
  };
};

const typesError = () => {
  return {
    type: TYPES_FAILURE,
  };
};

const requestInstituteCoaches = () => {
  return {
    type: INSTITUTE_COACHES_REQUEST,
  };
};

const receiveInstituteCoaches = (details) => {
  return {
    type: INSTITUTE_COACHES_SUCCESS,
    payload: details,
  };
};

const instituteCoachesError = () => {
  return {
    type: INSTITUTE_COACHES_FAILURE,
  };
};

const requestInstituteScouts = () => {
  return {
    type: INSTITUTE_SCOUTS_REQUEST,
  };
};

const receiveInstituteScouts = (details) => {
  return {
    type: INSTITUTE_SCOUTS_SUCCESS,
    payload: details,
  };
};

const instituteScoutsError = () => {
  return {
    type: INSTITUTE_SCOUTS_FAILURE,
  };
};

const requestInstituteAdd = () => {
  return {
    type: INSTITUTE_ADD_REQUEST,
  };
};

const receiveInstituteAdd = (details) => {
  return {
    type: INSTITUTE_ADD_SUCCESS,
    payload: details,
  };
};

const instituteAddError = (e) => {
  return {
    type: INSTITUTE_ADD_FAILURE,
    payload: e,
  };
};

const requestInstituteUpdate = () => {
  return {
    type: INSTITUTE_UPDATE_REQUEST,
  };
};

const receiveInstituteUpdate = (details) => {
  return {
    type: INSTITUTE_UPDATE_SUCCESS,
    payload: details,
  };
};

const instituteUpdateError = (e) => {
  return {
    type: INSTITUTE_UPDATE_FAILURE,
    payload: e,
  };
};

const requestInstituteMembersAdd = () => {
  return {
    type: INSTITUTE_MEMBERS_ADD_REQUEST,
  };
};

const receiveInstituteMembersAdd = (instituteId, roleData) => {
  return {
    type: INSTITUTE_MEMBERS_ADD_SUCCESS,
    payload: { [instituteId]: roleData },
  };
};

const instituteMembersAddError = (e) => {
  return {
    type: INSTITUTE_MEMBERS_ADD_FAILURE,
    payload: e,
  };
};

const requestInstituteScoutsAdd = () => {
  return {
    type: INSTITUTE_SCOUTS_ADD_REQUEST,
  };
};

const receiveInstituteScoutsAdd = (instituteId, scoutsData) => {
  return {
    type: INSTITUTE_SCOUTS_ADD_SUCCESS,
    payload: { [instituteId]: scoutsData },
  };
};

const instituteScoutsAddError = (e) => {
  return {
    type: INSTITUTE_SCOUTS_ADD_FAILURE,
    payload: e,
  };
};

const requestInstituteMembersDel = () => {
  return {
    type: INSTITUTE_MEMBERS_DEL_REQUEST,
  };
};

const receiveInstituteMembersDel = (instituteId, roleData) => {
  return {
    type: INSTITUTE_MEMBERS_DEL_SUCCESS,
    payload: { [instituteId]: roleData },
  };
};

const instituteMembersDelError = (e) => {
  return {
    type: INSTITUTE_MEMBERS_DEL_FAILURE,
    payload: e,
  };
};

const requestInstituteMembersInvite = () => {
  return {
    type: INSTITUTE_MEMBERS_INV_REQUEST,
  };
};

const receiveInstituteMembersInvite = (instituteId, roleData) => {
  return {
    type: INSTITUTE_MEMBERS_INV_SUCCESS,
    payload: { [instituteId]: roleData },
  };
};

const instituteMembersInviteError = (e) => {
  return {
    type: INSTITUTE_MEMBERS_INV_FAILURE,
    payload: e,
  };
};

export const fetchInstituteDetails = (instituteId) => async (dispatch) => {
  dispatch(requestInstituteDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}info/${instituteId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data.hasOwnProperty(instituteId))
      return dispatch(
        receiveInstituteDetails({ [instituteId]: json.data[instituteId] }),
      );
  } catch (error) {
    console.error(error);
    return dispatch(instituteDetailsError());
  }
};

/** comma separated institute ids */
export const fetchInstitutesDetails = (instituteIds) => async (dispatch) => {
  dispatch(requestInstituteDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}info/${instituteIds}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveInstituteDetails(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(instituteDetailsError());
  }
};

export const fetchOpenInstituteDetailsByUris = async (inst_uris) => {
  //comma separated uris, response is key value pair with uris as the key
  try {
    const response = await fetch(
      `${gt_config.urls.openinstitute}open/info_uri/${inst_uris}`,
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenInstituteScoutProfiles = async (instUri) => {
  try {
    const response = await fetch(
      anonymousGetRequest(
        `${gt_config.urls.opensports}open/all_scouts_uri/${instUri}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenInstituteAdverts = async (instUri) => {
  try {
    const response = await fetch(
      anonymousGetRequest(
        `${gt_config.urls.opensports}open/advert_institute_all_adverts_uri/${instUri}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenRandomInsts = async () => {
  try {
    const response = await fetch(
      `${gt_config.urls.openinstitute}open/random_insts`,
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchInstituteScouts = (instituteId) => async (dispatch) => {
  dispatch(requestInstituteScouts());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}scouts/${instituteId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data.hasOwnProperty(instituteId))
      return dispatch(
        receiveInstituteScouts({ [instituteId]: json.data[instituteId] }),
      );
  } catch (error) {
    console.error(error);
    return dispatch(instituteScoutsError());
  }
};

export const fetchInstituteCoaches = (instituteId) => async (dispatch) => {
  dispatch(requestInstituteCoaches());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}coaches/${instituteId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data.hasOwnProperty(instituteId))
      return dispatch(
        receiveInstituteCoaches({ [instituteId]: json.data[instituteId] }),
      );
  } catch (error) {
    console.error(error);
    return dispatch(instituteCoachesError());
  }
};

export const fetchRoles = () => async (dispatch) => {
  dispatch(requestRoles());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}roles`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveRoles(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(rolesError());
  }
};

export const fetchTypes = () => async (dispatch) => {
  dispatch(requestTypes());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.institute}types`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveTypes(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(typesError());
  }
};

export const add = (data) => async (dispatch) => {
  dispatch(requestInstituteAdd());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.institute}add`, data),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveInstituteAdd(json.data));

    return dispatch(instituteAddError(json.error || json.errors));
  } catch (error) {
    console.error(error);
    return dispatch(instituteAddError(error));
  }
};

export const checkUri = async (uri) => {
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.institute}uri`, { uri }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return true;
    else return json.error;
  } catch (error) {
    console.error('checkUri', error);
    // do nothing
  }
};

export const updateUri = (instituteId, uri) => async (dispatch) => {
  dispatch(requestInstituteUpdate());
  try {
    const response = await fetch(
      putJSONRequest(`${gt_config.urls.institute}uri`, {
        institute_id: instituteId,
        uri,
      }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status)
      return dispatch(receiveInstituteUpdate({ instituteId, uri }));
    else return dispatch(instituteUpdateError(json.error || json.errors));
  } catch (error) {
    console.error('updateUri', error);
    return dispatch(instituteUpdateError(error));
  }
};

export const addInstituteMemberForRole =
  (instituteId, role, memberUserId) => async (dispatch) => {
    dispatch(requestInstituteMembersAdd());
    try {
      const response = await fetch(
        getRequest(
          `${gt_config.urls.institute}members/${instituteId}/${role}/${memberUserId}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(
          receiveInstituteMembersAdd(instituteId, { [role]: memberUserId }),
        );
    } catch (error) {
      console.error(error);
      return dispatch(instituteMembersAddError(error));
    }
  };

export const addInstituteScout =
  (instituteId, sportId, scoutId) => async (dispatch) => {
    dispatch(requestInstituteScoutsAdd());
    try {
      const response = await fetch(
        postJSONRequest(
          `${gt_config.urls.institute}scout/${instituteId}/${sportId}/${scoutId}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(
          receiveInstituteScoutsAdd(instituteId, { [sportId]: scoutId }),
        );
    } catch (error) {
      console.error(error);
      return dispatch(instituteScoutsAddError(error));
    }
  };

export const addInstituteScouts =
  (instituteId, scoutData) => async (dispatch) => {
    dispatch(requestInstituteScoutsAdd());
    try {
      const response = await fetch(
        postJSONRequest(
          `${gt_config.urls.institute}scouts/${instituteId}`,
          scoutData,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(receiveInstituteScoutsAdd(instituteId, scoutData));
    } catch (error) {
      console.error(error);
      return dispatch(instituteScoutsAddError(error));
    }
  };

export const addInstituteMembers =
  (instituteId, memberData) => async (dispatch) => {
    dispatch(requestInstituteMembersAdd());
    try {
      const response = await fetch(
        postJSONRequest(
          `${gt_config.urls.institute}members/${instituteId}`,
          memberData,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(receiveInstituteMembersAdd(instituteId, memberData));
    } catch (error) {
      console.error(error);
      return dispatch(instituteMembersAddError(error));
    }
  };

export const inviteInstituteMembers =
  (instituteId, role, emails, mobiles) => async (dispatch) => {
    dispatch(requestInstituteMembersInvite());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.institute}invite/${instituteId}`, {
          role,
          emails,
          mobiles,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) return dispatch(receiveInstituteMembersInvite());
    } catch (error) {
      console.error(error);
      return dispatch(instituteMembersInviteError(error));
    }
  };

export const delInstituteMemberForRole =
  (instituteId, role, memberUserId) => async (dispatch) => {
    dispatch(requestInstituteMembersDel());
    try {
      const response = await fetch(
        deleteRequest(
          `${gt_config.urls.institute}members/${instituteId}/${role}/${memberUserId}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(
          receiveInstituteMembersDel(instituteId, { [role]: memberUserId }),
        );
    } catch (error) {
      console.error(error);
      return dispatch(instituteMembersDelError(error));
    }
  };

import { THEME_CHANGED } from '../actions/settings';

const initialState = {
  theme: 'light',
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case THEME_CHANGED:
      return Object.assign({}, state, { theme: action.payload });
    default:
      return state;
  }
};

export default settingsReducer;

export const THEME_CHANGED = 'THEME_CHANGED';

const updatedUserTheme = (newTheme) => {
  return {
    type: THEME_CHANGED,
    payload: newTheme,
  };
};

export const updateTheme = (newTheme) => (dispatch) => {
  dispatch(updatedUserTheme(newTheme));
};

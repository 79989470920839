import {
  FETCH_COUNTRIES,
  FETCH_LANG,
  FETCH_SPORTS,
  FETCH_INIT_VARS,
  SET_REFERRER,
  COMMON_REQUEST,
  COMMON_SUCCESS,
  COMMON_FAILURE,
  SET_NOBUTTON,
  SET_NOHEADER,
  SET_PAGETITLE,
  SET_PAGEMETATAGS,
  SET_PAGELINKS,
} from '../actions/common';

const initialState = {
  langs: null,
  sports: null,
  countries: null,
  initVars: null,
  siteReferrer: '',
  commonLoading: false,
  commonError: null,
  noButton: false,
  noHeader: false,
  pageMetaTags: [
    {
      name: 'description',
      property: 'description',
      content:
        'Connecting the ecosystem of sports. The best sports platform for coaches, scouts, agents and athletes. Check worlds best athletes, and athlete CVs. Apply to tryouts and scholarships. Add your academy, start searching for the best matches. Football, soccer, badminton, tennis, cricket, athletics, swimming, basketball, volleyball, E-Sports, Ice Hockey, American Football, MMA, Table Tennis, Gymnastics, Handball, Wrestling',
    },
  ],
  pageLinks: [
    {
      href: 'https://www.globaltalent.pro',
      rel: 'canonical',
    },
  ],
  pageTitle: 'Global Talent',
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INIT_VARS:
      return Object.assign({}, state, { initVars: action.payload });
    case FETCH_COUNTRIES:
      return Object.assign({}, state, { countries: action.payload });
    case FETCH_LANG:
      return Object.assign({}, state, { langs: action.payload });
    case FETCH_SPORTS:
      return Object.assign({}, state, { sports: action.payload });
    case SET_REFERRER:
      return Object.assign({}, state, { siteReferrer: action.payload });
    case SET_NOBUTTON:
      return Object.assign({}, state, { noButton: action.payload });
    case SET_NOHEADER:
      return Object.assign({}, state, { noHeader: action.payload });
    case SET_PAGETITLE:
      return Object.assign({}, state, { pageTitle: action.payload });
    case SET_PAGEMETATAGS:
      return Object.assign({}, state, { pageMetaTags: action.payload });
    case SET_PAGELINKS:
      return Object.assign({}, state, { pageLinks: action.payload });
    case COMMON_REQUEST:
      return Object.assign({}, state, {
        commonLoading: true,
        commonError: null,
      });
    case COMMON_SUCCESS:
      return Object.assign({}, state, {
        commonLoading: false,
        commonError: null,
      });
    case COMMON_FAILURE:
      return Object.assign({}, state, {
        commonLoading: false,
        commonError: action.payload,
      });
    default:
      return state;
  }
};

export default commonReducer;

import { TRYOUT_DETAILS_SUCCESS, TRYOUT_ADD_SUCCESS } from '../actions/tryout';

const initialState = {
  info: null,
  lastAddedTryoutId: null,
};

export const tryoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRYOUT_DETAILS_SUCCESS:
      const updatedTryoutsInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedTryoutsInfo });
    case TRYOUT_ADD_SUCCESS:
      const tryoutId = action.payload['advert_id'];
      const updatedInfo = { ...state.info, ...{ [tryoutId]: action.payload } };
      return Object.assign({}, state, {
        info: updatedInfo,
        lastAddedTryoutId: tryoutId,
      });
    default:
      return state;
  }
};

export default tryoutReducer;

import { USER_DETAILS_SUCCESS, USER_UPDATE_SUCCESS } from '../actions/user';

import { USER_OCCUPATION_CHANGE_SUCCESS } from '../actions/register';

const initialState = {
  info: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS_SUCCESS:
      return Object.assign({}, state, { info: action.payload });
    case USER_UPDATE_SUCCESS:
      const updated_info = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updated_info });
    case USER_OCCUPATION_CHANGE_SUCCESS:
      const updated_occ_info = { ...state.info };
      updated_occ_info['occupation'] = action.payload;
      return Object.assign({}, state, { info: updated_occ_info });
    default:
      return state;
  }
};

export default userReducer;

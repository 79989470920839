import { purgeStoredState } from 'redux-persist';

import { persistConfig } from './../index';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_FB_FAILURE,
  LOGOUT_REQUEST,
  DESTROY_SESSION,
  AUTH_TYPE,
} from '../actions/auth';

const initialState = {
  accessToken: null,
  refreshToken: null,
  refreshedAt: null,
  loginError: null,
  loginFBError: null,
  signinWith: null,
};

const currentTime = Date.now();

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: // firebase login is also handled by this case
      return {
        ...state,
        ...action.payload,
        ...{ loginError: null, refreshedAt: currentTime },
      };
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginError: { message: action.payload },
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, { loginError: null });
    case LOGIN_FB_FAILURE:
      return Object.assign({}, state, {
        loginFBError: { message: action.payload },
      });
    case AUTH_TYPE:
      return Object.assign({}, state, { signinWith: action.payload });
    case LOGOUT_REQUEST:
    case DESTROY_SESSION:
      purgeStoredState(persistConfig);
      return initialState;
    default:
      return state;
  }
};

export default authReducer;

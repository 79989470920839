import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import Button from '@mui/material/Button';

//import { IconButton, InputBase, Badge, Typography, Box } from '@mui/material/';
//import { styled } from '@mui/material/styles';
//import { Notifications, SearchOutlined } from '@mui/icons-material/';

//import Tooltip from '@mui/material/Tooltip';
//import AccountDropdown from './AccountDropdown';
//import TelegramIcon from '@mui/icons-material/Telegram';
//import FeedIcon from '@mui/icons-material/Dvr';
//import AppsIcon from '@mui/icons-material/Apps';

import TokenManager from '../../utils/tokenManager';
import * as ROUTES from '../../constants/routes';

const Navigation = ({ info, registrationEmail, noButton, noHeader }) =>
  noHeader ? (
    <p></p>
  ) : noButton ? (
    <NavigationNoButton />
  ) : !!info &&
    TokenManager.get() != null &&
    TokenManager.getRefresh() != null ? (
    <NavigationAuth authUser={info.name} />
  ) : registrationEmail ? (
    <NavigationAuth authUser={registrationEmail} />
  ) : (
    <NavigationNonAuth />
  );

/*const HtmlTooltip = styled(Tooltip)(() => ({
  backgroundColor: '#e1e1e150',
  color: '#2f2f2f',
  maxWidth: 220,
  fontSize: '14px',
  fontWeight: '400',
  padding: '10px 10px',
  borderRadius: '4px',
}));*/

const classes = (theme) => ({
  grow: {
    flexGrow: 1,
    height: '3.75rem',
  },
  appBar: {
    backgroundColor: '#1f1f33',
  },
  toolBar: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  brandName: {
    marginRight: '32px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icons: {
    color: '#999eff',
    width: '36px',
    height: '36px',
    marginRight: '16px',
    border: '1px solid #999eff80',
    '& .MuiSvgIcon-root': {
      width: '0.9em',
      height: '0.9em',
    },
    '&:hover': {
      color: '#e8d5b5',
      border: '1px solid #e8d5b530',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '8px',
    },
  },
  iconSetTwo: {
    color: '#999eff',
    width: '36px',
    height: '36px',
    //marginRight: '16px',
    border: '1px solid #999eff80',
    '& .MuiSvgIcon-root': {
      width: '0.9em',
      height: '0.9em',
    },
    '&:hover': {
      color: '#e8d5b5',
      border: '1px solid #e8d5b530',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '8px',
    },
  },
  sectionDesktop: {
    display: 'flex',
    flexDirection: 'row',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navToolbarAuth: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },

  navToolbar: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
  },

  notification: {
    color: '#999eff',
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(4),
    border: '1px solid #ffffff30',
    '&:hover': {
      color: '#e8d5b5',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  feed: {
    color: '#999eff',
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(1),
    border: '1px solid #ffffff30',
    '&:hover': {
      color: '#e8d5b5',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  iconText: {
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    color: '#aba9bb',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  searchInput: {
    padding: '1px 12px',
    marginRight: '16px',
    width: 'auto',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      //marginRight: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0px',
    },
    fontSize: '14px',
    backgroundColor: '#6d6c8e20',
    color: '#fff',
    border: '1px solid #a7a7af66',
    borderRadius: '999px',
    '&:hover': {
      backgroundColor: '#6d6c8e50',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '8px',
    },
  },
  btnUser: {
    borderRadius: '999px',
    padding: '2px 10px',
    border: '1px solid #a7a7af20',
    backgroundColor: '#6d6c8e00',
    '&:hover': {
      backgroundColor: '#6d6c8e50',
    },
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    border: '1px solid #a7a7af20',
    background: 'hsl(242, 8%, 40%,0)',
    '&:hover': {
      backgroundColor: 'hsl(0,40%,50%,0.5)',
    },
    '& .MuiBadge-colorSecondary': {
      backgroundColor: '#ff0000',
    },
  },
});

const NavigationAuth = (props) => {
  const theme = useTheme();

  return (
    <Box sx={classes(theme).grow}>
      <AppBar position="fixed" sx={classes(theme).appBar}>
        <ToolBar sx={classes(theme).toolBar}>
          <IconButton
            edge="start"
            sx={classes(theme).logo}
            component={RouterLink}
            to={{ pathname: ROUTES.HOME, state: { from: props.location } }}
          >
            <img
              src="https://assets.globaltalent.pro/img/logo-3d.webp"
              alt="logo"
              width="40"
              height="40"
            />
          </IconButton>
          <Typography sx={classes(theme).brandName} color="inherit">
            Global Talent
          </Typography>
          {/*<div style={classes(theme).sectionDesktop}>
            <HtmlTooltip title="Notifications" enterDelay={30}>
              <IconButton sx={classes(theme).icons}>
                <Badge badgeContent={0} color="primary">
                  <Notifications />
                </Badge>
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip title="Feed" enterDelay={30}>
              <IconButton sx={classes(theme).icons}>
                <Badge>
                  <FeedIcon />
                </Badge>
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip title="Messages" enterDelay={30}>
              <IconButton sx={classes(theme).icons}>
                <Badge>
                  <TelegramIcon />
                </Badge>
              </IconButton>
            </HtmlTooltip>
          </div>
          <div style={classes(theme).grow}></div>
          <InputBase
            fullWidth
            sx={classes(theme).searchInput}
            placeholder="Search Global Talent"
            startAdornment={<SearchOutlined fontSize="small" />}
          />
          <div style={classes(theme).sectionDesktop}>
            <HtmlTooltip title="Settings" enterDelay={30}>
              <IconButton sx={classes(theme).iconSetTwo}>
                <AppsIcon />
              </IconButton>
            </HtmlTooltip>
          </div>*/}
          {/*<AccountDropdown/>*/}
        </ToolBar>
      </AppBar>
    </Box>
  );
};

const NavigationNonAuth = (props) => {
  const theme = useTheme();

  return (
    <Box sx={classes(theme).grow}>
      <AppBar position="fixed" sx={classes(theme).appBar}>
        <ToolBar sx={classes(theme).toolBar}>
          <IconButton
            edge="start"
            sx={classes(theme).logo}
            component={RouterLink}
            to={{ pathname: ROUTES.LANDING, state: { from: props.location } }}
          >
            <img
              src="https://assets.globaltalent.pro/img/logo-3d.webp"
              alt="logo"
              width="40"
              height="40"
            />
          </IconButton>
          <div style={classes(theme).grow}></div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            component={RouterLink}
            to={{ pathname: ROUTES.SIGN_IN, state: { from: props.location } }}
            style={{
              fontSize: '11px',
              padding: '6px 24px',
              borderRadius: '3px',
            }}
          >
            Log In
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            component={RouterLink}
            to={{ pathname: ROUTES.SIGN_UP, state: { from: props.location } }}
            style={{
              marginLeft: '8px',
              fontSize: '11px',
              padding: '6px 24px',
              borderRadius: '3px',
            }}
          >
            Join Now
          </Button>
        </ToolBar>
      </AppBar>
      {/* <div sx={styles.toolbar}></div> */}
    </Box>
  );
};

const NavigationNoButton = () => {
  const theme = useTheme();

  return (
    <Box sx={classes(theme).grow}>
      <AppBar position="fixed" sx={classes(theme).appBar}>
        <ToolBar sx={classes(theme).toolBar}>
          <IconButton edge="start" sx={classes(theme).logo}>
            <img
              src="https://assets.globaltalent.pro/img/logo-3d.webp"
              alt="logo"
              width="40"
              height="40"
            />
          </IconButton>
          <div style={classes(theme).grow}></div>
        </ToolBar>
      </AppBar>
      {/* <div sx={styles.toolbar}></div> */}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  info: state.user.info,
  registrationEmail: state.register.email,
  noButton: state.common.noButton,
  noHeader: state.common.noHeader,
});

export default connect(mapStateToProps)(Navigation);

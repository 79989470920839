//import './App.css';

import ReactGA from 'react-ga';
import React, { Suspense, lazy, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { svSE, enUS, jaJP, deDE, esES } from '@mui/material/locale';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import ProtectedRoute from './containers/ProtectedRoute';
import UnguardedRoute from './containers/UnguardedRoute';
import Navigation from './containers/Navigation';

import defaultTheme from './themes/defaultTheme';

import * as ROUTES from './constants/routes';
import InjectGlobalStyles from './utils/globalStyles';
import {
  fetchInitVars,
  setReferrer,
  dispatchNoButton,
  dispatchNoHeader,
} from './store/actions/common';
import { refreshToken } from './store/actions/auth';
import { gt_config } from './configs';

const LandingPage = lazy(() => import('./containers/Landing'));
const SignOutPage = lazy(() => import('./containers/Signout'));
const About = lazy(() => import('./containers/StaticPages/About'));
const Privacy = lazy(() => import('./containers/StaticPages/Privacy'));
const Tnc = lazy(() => import('./containers/StaticPages/Tnc'));
const OpenSearch = lazy(() => import('./containers/OpenSearch'));
const OpenUserProfile = lazy(() => import('./containers/User/openprofile'));
const OpenTeamProfile = lazy(() => import('./containers/Team/openprofile'));
const OpenInstituteProfile = lazy(() =>
  import('./containers/Institute/openprofile'),
);

const SignUpPage = lazy(() => import('./containers/Signup'));
const SignInPage = lazy(() => import('./containers/Signin'));
const ForgotPassword = lazy(() => import('./containers/ForgotPassword'));
const HomePage = lazy(() => import('./containers/Home'));
const Athlete = lazy(() => import('./containers/Athlete'));
const Coach = lazy(() => import('./containers/Coach'));
const Scout = lazy(() => import('./containers/Scout'));
const Tryout = lazy(() => import('./containers/Tryout'));
const Market = lazy(() => import('./containers/Market'));
const Team = lazy(() => import('./containers/Team'));
const Institute = lazy(() => import('./containers/Institute'));
const Contract = lazy(() => import('./containers/Contract'));

const NotFoundPage = lazy(() => import('./containers/StaticPages/NotFound'));

ReactGA.initialize('UA-142608617-1');

const getLocale = (userLocale) => {
  switch (userLocale) {
    case 'sv_SE':
      return svSE;
    case 'de_DE':
      return deDE;
    case 'ja_JP':
      return jaJP;
    case 'es_ES':
      return esES;
    default:
      return enUS;
  }
};

const App = (props) => {
  //https://dmitripavlutin.com/react-useeffect-explanation/
  useEffect(() => {
    props.setReferrer(window.document.referrer);
    if (
      process.env.REACT_APP_NODE_ENV !== 'development' &&
      process.env.REACT_APP_NODE_ENV !== undefined &&
      window &&
      gt_config.host &&
      gt_config.host.indexOf(window.location.origin) === -1
    ) {
      window.location.assign(gt_config.host);
    }
    if (props.accessToken) getNewToken();
    if (props.noHeader) props.dispatchNoHeader(false);
    if (props.noButton) props.dispatchNoButton(false);
  }, []);

  let tokenExpire = null;

  const getNewToken = () => {
    props.refreshToken();
    if (tokenExpire !== null) {
      clearTimeout(tokenExpire);
      tokenExpire = null;
    }
    tokenExpire = setTimeout(() => getNewToken(), gt_config.jwtRefresh * 1000);
  };

  return (
    <ThemeProvider
      theme={
        props.accessToken
          ? createTheme(
              {
                ...defaultTheme,
                ...{
                  palette: {
                    ...defaultTheme.palette,
                    ...{ mode: props.currentTheme },
                  },
                },
              },
              getLocale(),
            )
          : createTheme(defaultTheme, getLocale()) // default theme
      }
    >
      <Helmet
        defaultTitle={props.pageTitle}
        defer={false}
        encodeSpecialCharacters
        htmlAttributes={{ lang: 'en-US' }}
        titleAttributes={{ itemprop: 'name', lang: 'en-US' }}
        titleTemplate={`%s | ${props.pageTitle}`}
        meta={props.pageMetaTags}
        link={props.pageLinks}
      ></Helmet>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        {InjectGlobalStyles}
      </StyledEngineProvider>
      <Router>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            duration: 5000,
          }}
        />
        <Navigation />
        {/**<ToggleTheme />*/}
        <Suspense fallback={<div></div>}>
          <Switch>
            <UnguardedRoute
              exact
              path={ROUTES.LANDING}
              component={LandingPage}
            />
            <Route path={ROUTES.SIGN_OUT} component={SignOutPage} />
            <Route exact path={ROUTES.ABOUT} component={About} />
            <Route exact path={ROUTES.PRIVACY} component={Privacy} />
            <Route exact path={ROUTES.TNC} component={Tnc} />
            <Route exact path={ROUTES.OPENSEARCH} component={OpenSearch} />
            <UnguardedRoute
              exact
              path={ROUTES.OPEN_INFO_USER}
              component={OpenUserProfile}
            />
            <UnguardedRoute
              exact
              path={ROUTES.OPEN_INFO_TEAM}
              component={OpenTeamProfile}
            />
            <UnguardedRoute
              exact
              path={ROUTES.OPEN_INFO_INSTITUTE}
              component={OpenInstituteProfile}
            />
            <UnguardedRoute path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.PASSWORD_FORGOT} component={ForgotPassword} />
            <ProtectedRoute path={ROUTES.HOME} component={HomePage} />
            <ProtectedRoute path={ROUTES.ATHLETE} component={Athlete} />
            <ProtectedRoute path={ROUTES.COACH} component={Coach} />
            <ProtectedRoute path={ROUTES.SCOUT} component={Scout} />
            <ProtectedRoute path={ROUTES.TRYOUT} component={Tryout} />
            <ProtectedRoute path={ROUTES.TEAM} component={Team} />
            <ProtectedRoute path={ROUTES.INSTITUTE} component={Institute} />
            <ProtectedRoute path={ROUTES.MARKET} component={Market} />
            <ProtectedRoute path={ROUTES.CONTRACT} component={Contract} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: state.settings.theme,
  accessToken: state.auth.accessToken,
  initVars: state.common.initVars,
  pageTitle: state.common.pageTitle,
  pageMetaTags: state.common.pageMetaTags,
  pageLinks: state.common.pageLinks,
  noButton: state.common.noButton,
  noHeader: state.common.noHeader,
});

const mapDispatchToProps = {
  fetchInitVars,
  setReferrer,
  refreshToken,
  dispatchNoButton,
  dispatchNoHeader,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_FIREBASE_REGISTER_SUCCESS,
  USER_FIREBASE_REGISTER_FAILURE,
  USER_SIGNUP_RESEND_OTP,
  USER_SIGNUP_SEND_OTP,
  USER_ONBOARDBASIC_SUCCESS,
  USER_ONBOARDBASIC_FAILURE,
  USER_ONBOARDING_CHOICES,
  USER_OCCUPATION,
  GUARDIAN_REGISTER_SUCCESS,
  WARD_REGISTER_SUCCESS,
  REGISTER_TYPE,
  REGISTRATION_START,
  REGISTRATION_END,
} from '../actions/register';

const initialState = {
  errors: null,
  onBoardErrors: null,
  email: null,
  mobile: null,
  guardianEmail: null,
  guardianMobile: null,
  wardEmail: [],
  wardMobile: [],
  firebaseSignup: false,
  resentOTP: false,
  sentOTP: false,
  choicesData: null,
  registrationSport: null,
  occupation: 2,
  signupWith: null,
  registrationStatus: null,
};

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_FAILURE:
    case USER_FIREBASE_REGISTER_FAILURE:
      return Object.assign({}, state, { errors: action.payload });
    case USER_REGISTER_SUCCESS:
      return action.payload.email
        ? Object.assign({}, state, { email: action.payload.email })
        : Object.assign({}, state, { mobile: action.payload.mobile });
    case USER_ONBOARDBASIC_SUCCESS:
      return Object.assign({}, state, { registrationSport: action.payload });
    case GUARDIAN_REGISTER_SUCCESS:
      return action.payload.email
        ? Object.assign({}, state, { guardianEmail: action.payload.email })
        : Object.assign({}, state, { guardianMobile: action.payload.mobile });
    case WARD_REGISTER_SUCCESS:
      const existing = state.wardEmail;
      return action.payload.email
        ? Object.assign({}, state, {
            wardEmail: [...existing, action.payload.email],
          })
        : Object.assign({}, state, {
            wardMobile: [...existing, action.payload.mobile],
          });
    case USER_OCCUPATION:
      return Object.assign({}, state, { occupation: action.payload });
    case USER_FIREBASE_REGISTER_SUCCESS:
      return Object.assign({}, state, { firebaseSignup: true });
    case USER_SIGNUP_RESEND_OTP:
      return Object.assign({}, state, { resentOTP: true });
    case USER_SIGNUP_SEND_OTP:
      return Object.assign({}, state, { sentOTP: true });
    case USER_ONBOARDBASIC_FAILURE:
      return Object.assign({}, state, { onBoardErrors: action.payload });
    case USER_ONBOARDING_CHOICES:
      return Object.assign({}, state, { choicesData: action.payload });
    case REGISTER_TYPE:
      return Object.assign({}, state, { signupWith: action.payload });
    case REGISTRATION_START:
      return Object.assign({}, state, { registrationStatus: 1 });
    case REGISTRATION_END:
      return Object.assign({}, state, { registrationStatus: 2 });
    default:
      return state;
  }
};

export default registerReducer;

import {
  deleteRequest,
  getRequest,
  postJSONRequest,
  putJSONRequest,
  anonymousGetRequest,
} from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const TEAM_DETAILS_REQUEST = 'TEAM_DETAILS_REQUEST';
export const TEAM_DETAILS_SUCCESS = 'TEAM_DETAILS_SUCCESS';
export const TEAM_DETAILS_FAILURE = 'TEAM_DETAILS_FAILURE';

export const SPORT_ROLES_REQUEST = 'SPORT_ROLES_REQUEST';
export const SPORT_ROLES_SUCCESS = 'SPORT_ROLES_SUCCESS';
export const SPORT_ROLES_FAILURE = 'SPORT_ROLES_FAILURE';

export const TEAM_ADD_REQUEST = 'TEAM_ADD_REQUEST';
export const TEAM_ADD_SUCCESS = 'TEAM_ADD_SUCCESS';
export const TEAM_ADD_FAILURE = 'TEAM_ADD_FAILURE';

export const TEAM_UPDATE_REQUEST = 'TEAM_UPDATE_REQUEST';
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS';
export const TEAM_UPDATE_FAILURE = 'TEAM_UPDATE_FAILURE';

export const TEAM_MEMBERS_ADD_REQUEST = 'TEAM_MEMBERS_ADD_REQUEST';
export const TEAM_MEMBERS_ADD_SUCCESS = 'TEAM_MEMBERS_ADD_SUCCESS';
export const TEAM_MEMBERS_ADD_FAILURE = 'TEAM_MEMBERS_ADD_FAILURE';

export const TEAM_MEMBERS_DEL_REQUEST = 'TEAM_MEMBERS_DEL_REQUEST';
export const TEAM_MEMBERS_DEL_SUCCESS = 'TEAM_MEMBERS_DEL_SUCCESS';
export const TEAM_MEMBERS_DEL_FAILURE = 'TEAM_MEMBERS_DEL_FAILURE';

export const TEAM_MEMBERS_INV_REQUEST = 'TEAM_MEMBERS_INV_REQUEST';
export const TEAM_MEMBERS_INV_SUCCESS = 'TEAM_MEMBERS_INV_SUCCESS';
export const TEAM_MEMBERS_INV_FAILURE = 'TEAM_MEMBERS_INV_FAILURE';

const requestTeamDetails = () => {
  return {
    type: TEAM_DETAILS_REQUEST,
  };
};

const receiveTeamDetails = (details) => {
  return {
    type: TEAM_DETAILS_SUCCESS,
    payload: details,
  };
};

const teamDetailsError = () => {
  return {
    type: TEAM_DETAILS_FAILURE,
  };
};

const requestSportRoles = () => {
  return {
    type: SPORT_ROLES_REQUEST,
  };
};

const receiveSportRoles = (sportId, details) => {
  return {
    type: SPORT_ROLES_SUCCESS,
    payload: { [sportId]: details },
  };
};

const sportRolesError = () => {
  return {
    type: SPORT_ROLES_FAILURE,
  };
};

const requestTeamAdd = () => {
  return {
    type: TEAM_ADD_REQUEST,
  };
};

const receiveTeamAdd = (details) => {
  return {
    type: TEAM_ADD_SUCCESS,
    payload: details,
  };
};

const teamAddError = (e) => {
  return {
    type: TEAM_ADD_FAILURE,
    payload: e,
  };
};

const requestTeamUpdate = () => {
  return {
    type: TEAM_UPDATE_REQUEST,
  };
};

const receiveTeamUpdate = (details) => {
  return {
    type: TEAM_UPDATE_SUCCESS,
    payload: details,
  };
};

const teamUpdateError = (e) => {
  return {
    type: TEAM_UPDATE_FAILURE,
    payload: e,
  };
};

const requestTeamMembersAdd = () => {
  return {
    type: TEAM_MEMBERS_ADD_REQUEST,
  };
};

const receiveTeamMembersAdd = (teamId, roleData) => {
  return {
    type: TEAM_MEMBERS_ADD_SUCCESS,
    payload: { [teamId]: roleData },
  };
};

const teamMembersAddError = (e) => {
  return {
    type: TEAM_MEMBERS_ADD_FAILURE,
    payload: e,
  };
};

const requestTeamMembersDel = () => {
  return {
    type: TEAM_MEMBERS_DEL_REQUEST,
  };
};

const receiveTeamMembersDel = (teamId, roleData) => {
  return {
    type: TEAM_MEMBERS_DEL_SUCCESS,
    payload: { [teamId]: roleData },
  };
};

const teamMembersDelError = (e) => {
  return {
    type: TEAM_MEMBERS_DEL_FAILURE,
    payload: e,
  };
};

const requestTeamMembersInvite = () => {
  return {
    type: TEAM_MEMBERS_INV_REQUEST,
  };
};

const receiveTeamMembersInvite = (teamId, roleData) => {
  return {
    type: TEAM_MEMBERS_INV_SUCCESS,
    payload: { [teamId]: roleData },
  };
};

const teamMembersInviteError = (e) => {
  return {
    type: TEAM_MEMBERS_INV_FAILURE,
    payload: e,
  };
};

export const fetchTeamDetails = (teamId) => async (dispatch) => {
  dispatch(requestTeamDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.team}info/${teamId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.data.hasOwnProperty(teamId))
      return dispatch(receiveTeamDetails({ [teamId]: json.data[teamId] }));
  } catch (error) {
    console.error(error);
    return dispatch(teamDetailsError());
  }
};

/** comma separated team ids */
export const fetchTeamsDetails = (teamIds) => async (dispatch) => {
  dispatch(requestTeamDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.team}info/${teamIds}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveTeamDetails(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(teamDetailsError());
  }
};

export const fetchOpenTeamDetailsByUris = async (team_uris) => {
  //comma separated uris, response is key value pair with uris as the key
  try {
    const response = await fetch(
      `${gt_config.urls.openteam}open/info_uri/${team_uris}`,
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenTeamScoutProfile = async (teamUri) => {
  try {
    const response = await fetch(
      anonymousGetRequest(
        `${gt_config.urls.opensports}open/team_scout_profile_uri/${teamUri}`,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchOpenRandomTeams = async () => {
  try {
    const response = await fetch(`${gt_config.urls.openteam}open/random_teams`);
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return json.data;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchSportRoles = (sportId) => async (dispatch) => {
  dispatch(requestSportRoles());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.team}roles/${sportId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveSportRoles(sportId, json.data));
  } catch (error) {
    console.error(error);
    return dispatch(sportRolesError());
  }
};

export const add = (data) => async (dispatch) => {
  dispatch(requestTeamAdd());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.team}add`, data),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveTeamAdd(json.data));

    return dispatch(teamAddError(json.error || json.errors));
  } catch (error) {
    console.error(error);
    return dispatch(teamAddError(error));
  }
};

export const checkUri = async (uri) => {
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.team}uri`, { uri }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return true;
    else return json.error;
  } catch (error) {
    console.error('checkUri', error);
    // do nothing
  }
};

export const updateUri = (teamId, uri) => async (dispatch) => {
  dispatch(requestTeamUpdate());
  try {
    const response = await fetch(
      putJSONRequest(`${gt_config.urls.team}uri`, { team_id: teamId, uri }),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveTeamUpdate({ teamId, uri }));
    else return dispatch(teamUpdateError(json.error));
  } catch (error) {
    console.error('updateUri', error);
    return dispatch(teamUpdateError(error));
  }
};

export const addTeamMemberForRole =
  (teamId, role, memberUserId) => async (dispatch) => {
    dispatch(requestTeamMembersAdd());
    try {
      const response = await fetch(
        getRequest(
          `${gt_config.urls.team}members/${teamId}/${role}/${memberUserId}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(
          receiveTeamMembersAdd(teamId, { [role]: memberUserId }),
        );
    } catch (error) {
      console.error(error);
      return dispatch(teamMembersAddError(error));
    }
  };

export const addTeamMembers = (teamId, roleData) => async (dispatch) => {
  dispatch(requestTeamMembersAdd());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.team}members/${teamId}`, roleData),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveTeamMembersAdd(teamId, roleData));
  } catch (error) {
    console.error(error);
    return dispatch(teamMembersAddError(error));
  }
};

export const inviteTeamMembers =
  (teamId, role, emails, mobiles) => async (dispatch) => {
    dispatch(requestTeamMembersInvite());
    try {
      const response = await fetch(
        postJSONRequest(`${gt_config.urls.team}invite/${teamId}`, {
          role,
          emails,
          mobiles,
        }),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status) return dispatch(receiveTeamMembersInvite());
    } catch (error) {
      console.error(error);
      return dispatch(teamMembersInviteError(error));
    }
  };

export const delTeamMemberForRole =
  (teamId, role, memberUserId) => async (dispatch) => {
    dispatch(requestTeamMembersDel());
    try {
      const response = await fetch(
        deleteRequest(
          `${gt_config.urls.team}members/${teamId}/${role}/${memberUserId}`,
        ),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      if (json.status)
        return dispatch(
          receiveTeamMembersDel(teamId, { [role]: memberUserId }),
        );
    } catch (error) {
      console.error(error);
      return dispatch(teamMembersDelError(error));
    }
  };

import {
  COACH_PROFILE_SUCCESS,
  COACH_PROFILE_ADD_SUCCESS,
} from '../actions/coach';

const initialState = {
  info: null,
  lastAddedCoachId: null,
  lastAddedCoachIsScout: false,
};

export const coachReducer = (state = initialState, action) => {
  switch (action.type) {
    case COACH_PROFILE_SUCCESS:
      const updatedCoachesInfo = { ...state.info, ...action.payload };
      return Object.assign({}, state, { info: updatedCoachesInfo });
    case COACH_PROFILE_ADD_SUCCESS:
      const coachId = action.payload['coach'];
      const scoutStatus = action.payload['scoutStatus'];
      const updatedInfo = { ...state.info, ...{ [coachId]: action.payload } };
      return Object.assign({}, state, {
        info: updatedInfo,
        lastAddedCoachId: coachId,
        lastAddedCoachIsScout: scoutStatus,
      });
    default:
      return state;
  }
};

export default coachReducer;

import production from './prod.json';
import development from './dev.json';
import stage from './stage.json';

export const gt_config =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? production
    : process.env.REACT_APP_NODE_ENV === 'stage'
    ? stage
    : development;

import { getRequest, postJSONRequest } from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const ATHLETE_SPORTS_DETAILS_REQUEST = 'ATHLETE_SPORTS_DETAILS_REQUEST';
export const ATHLETE_SPORTS_DETAILS_SUCCESS = 'ATHLETE_SPORTS_DETAILS_SUCCESS';
export const ATHLETE_SPORTS_DETAILS_FAILURE = 'ATHLETE_SPORTS_DETAILS_FAILURE';
export const ATHLETE_SPORTS_UPDATE_REQUEST = 'ATHLETE_SPORTS_UPDATE_REQUEST';
export const ATHLETE_SPORTS_UPDATE_SUCCESS = 'ATHLETE_SPORTS_UPDATE_SUCCESS';
export const ATHLETE_SPORTS_UPDATE_FAILURE = 'ATHLETE_SPORTS_UPDATE_FAILURE';

export const ATHLETE_ACAD_DETAILS_REQUEST = 'ATHLETE_ACAD_DETAILS_REQUEST';
export const ATHLETE_ACAD_DETAILS_SUCCESS = 'ATHLETE_ACAD_DETAILS_SUCCESS';
export const ATHLETE_ACAD_DETAILS_FAILURE = 'ATHLETE_ACAD_DETAILS_FAILURE';
export const ATHLETE_ACAD_UPDATE_REQUEST = 'ATHLETE_ACAD_UPDATE_REQUEST';
export const ATHLETE_ACAD_UPDATE_SUCCESS = 'ATHLETE_ACAD_UPDATE_SUCCESS';
export const ATHLETE_ACAD_UPDATE_FAILURE = 'ATHLETE_ACAD_UPDATE_FAILURE';

export const ATHLETE_AGENT_UPDATE_REQUEST = 'ATHLETE_AGENT_UPDATE_REQUEST';
export const ATHLETE_AGENT_UPDATE_SUCCESS = 'ATHLETE_AGENT_UPDATE_SUCCESS';
export const ATHLETE_AGENT_UPDATE_FAILURE = 'ATHLETE_AGENT_UPDATE_FAILURE';
export const ATHLETE_AGENT_REQUEST = 'ATHLETE_AGENT_REQUEST';
export const ATHLETE_AGENT_SUCCESS = 'ATHLETE_AGENT_SUCCESS';
export const ATHLETE_AGENT_FAILURE = 'ATHLETE_AGENT_FAILURE';

const requestFetchAgent = () => {
  return {
    type: ATHLETE_AGENT_REQUEST,
  };
};

const receiveFetchAgent = (agent) => {
  return {
    type: ATHLETE_AGENT_SUCCESS,
    payload: agent,
  };
};

const fetchAgentError = (error) => {
  return {
    type: ATHLETE_AGENT_FAILURE,
    payload: error,
  };
};

const requestAddAgent = () => {
  return {
    type: ATHLETE_AGENT_UPDATE_REQUEST,
  };
};

const receiveAddAgent = (agentData) => {
  return {
    type: ATHLETE_AGENT_UPDATE_SUCCESS,
    payload: agentData,
  };
};

const addAgentError = (error) => {
  return {
    type: ATHLETE_AGENT_UPDATE_FAILURE,
    payload: error,
  };
};

const requestSportsDetails = () => {
  return {
    type: ATHLETE_SPORTS_DETAILS_REQUEST,
  };
};

const receiveSportsDetails = (sportsData) => {
  return {
    type: ATHLETE_SPORTS_DETAILS_SUCCESS,
    payload: sportsData,
  };
};

const sportsDetailsError = (error) => {
  return {
    type: ATHLETE_SPORTS_DETAILS_FAILURE,
    payload: error,
  };
};

const requestAddSportsDetails = () => {
  return {
    type: ATHLETE_SPORTS_UPDATE_REQUEST,
  };
};

const receiveAddSportsDetails = (sportsData) => {
  return {
    type: ATHLETE_SPORTS_UPDATE_SUCCESS,
    payload: sportsData,
  };
};

const addSportsDetailsError = (error) => {
  return {
    type: ATHLETE_SPORTS_UPDATE_FAILURE,
    payload: error,
  };
};

const requestAcademicDetails = () => {
  return {
    type: ATHLETE_ACAD_DETAILS_REQUEST,
  };
};

const receiveAcademicDetails = (acaddData) => {
  return {
    type: ATHLETE_ACAD_DETAILS_SUCCESS,
    payload: acaddData,
  };
};

const academicDetailsError = (error) => {
  return {
    type: ATHLETE_ACAD_DETAILS_FAILURE,
    payload: error,
  };
};

const requestAddAcademicDetails = () => {
  return {
    type: ATHLETE_ACAD_UPDATE_REQUEST,
  };
};

const receiveAddAcademicDetails = (acadData) => {
  return {
    type: ATHLETE_ACAD_UPDATE_SUCCESS,
    payload: acadData,
  };
};

const addAcademicDetailsError = (error) => {
  return {
    type: ATHLETE_ACAD_UPDATE_FAILURE,
    payload: error,
  };
};

export const addAgent = (player_id, sport_id, data) => async (dispatch) => {
  dispatch(requestAddAgent());
  try {
    const response = await fetch(
      postJSONRequest(
        `${gt_config.urls.sports}agent/${player_id}/${sport_id}`,
        data,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveAddAgent(json.data));

    return dispatch(addAgentError(json.msg));
  } catch (error) {
    console.error(error);
    return dispatch(addAgentError(error));
  }
};

export const fetchAgent = (player_id, sport_id) => async (dispatch) => {
  dispatch(requestFetchAgent());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}agent/${player_id}/${sport_id}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveFetchAgent(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(fetchAgentError(error));
  }
};

export const fetchSportProfile =
  (player_id, sport_id, data) => async (dispatch) => {
    dispatch(requestSportsDetails());
    try {
      const response = await fetch(
        getRequest(`${gt_config.urls.sports}agent/${player_id}/${sport_id}`),
      );
      const res = await handleAPIError(response);
      const json = await res.json();
      return dispatch(receiveSportsDetails(json.data.info));
    } catch (error) {
      console.error(error);
      return dispatch(sportsDetailsError(error));
    }
  };

export const addSportProfile =
  (player_id, sport_id, data) => async (dispatch) => {
    dispatch(requestAddSportsDetails());
    try {
      let response = await fetch(
        postJSONRequest(
          `${gt_config.urls.sports}sport_profile/${player_id}/${sport_id}`,
          data,
        ),
      );
      let res = await handleAPIError(response);
      let json = await res.json();

      response = await fetch(
        postJSONRequest(`${gt_config.urls.registration}onboard/athlete/`, data),
      );
      res = await handleAPIError(response);
      json = await res.json();
      return dispatch(receiveAddSportsDetails(data));
    } catch (error) {
      console.error(error);
      return dispatch(addSportsDetailsError(error));
    }
  };

export const fetchAcademicProfile = (player_id) => async (dispatch) => {
  dispatch(requestAcademicDetails());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}player_profile/${player_id}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveAcademicDetails(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(academicDetailsError(error));
  }
};

export const addAcademicProfile = (player_id, data) => async (dispatch) => {
  dispatch(requestAddAcademicDetails());
  try {
    const response = await fetch(
      postJSONRequest(
        `${gt_config.urls.sports}player_profile/${player_id}`,
        data,
      ),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveAddAcademicDetails(json.data));
  } catch (error) {
    console.error(error);
    return dispatch(addAcademicDetailsError(error));
  }
};

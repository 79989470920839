import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Container from '@mui/material/Container';
import TokenManager from '../utils/tokenManager';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props) =>
        TokenManager.get() != null && TokenManager.getRefresh() != null ? (
          <Container maxWidth="xl" disableGutters>
            <Component {...props} />
          </Container>
        ) : (
          <Redirect
            to={{ pathname: '/signin/', state: { from: props.location } }}
          />
        )
      }
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ProtectedRoute);

import {
  SCOUTINGPROFILE_DETAILS_SUCCESS,
  SCOUTINGPROFILE_ADD_SUCCESS,
} from '../actions/scoutingProfile';

const initialState = {
  info: null,
  team: null,
  inst: null,
  lastScoutingProfileId: null,
};

export const scoutingProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCOUTINGPROFILE_DETAILS_SUCCESS:
      const updatedScoutingProfileInfo = { ...state.info, ...action.payload };
      const payload = action.payload[Object.keys(action.payload)[0]];
      let teamId = payload['team_id'];
      if (teamId > 1) {
        const updatedTeamInfo = { ...state.team, ...{ [teamId]: payload } };
        return Object.assign(
          {},
          state,
          { team: updatedTeamInfo },
          { info: updatedScoutingProfileInfo },
        );
      } else {
        let instId = payload['inst_id'];
        let sportId = payload['sport_id'];
        let instSportInfo = {};
        if (state.inst && state.inst.hasOwnProperty(instId)) {
          // this sport and inst already exisit
          instSportInfo = { ...state.inst[instId], ...{ [sportId]: payload } };
          const updatedInstInfo = {
            ...state.inst,
            ...{ [instId]: instSportInfo },
          };
          return Object.assign(
            {},
            state,
            { inst: updatedInstInfo },
            { info: updatedScoutingProfileInfo },
          );
        } else {
          instSportInfo = { [instId]: { [sportId]: payload } };
          const updatedInstInfo = { ...state.inst, ...instSportInfo };
          return Object.assign(
            {},
            state,
            { inst: updatedInstInfo },
            { info: updatedScoutingProfileInfo },
          );
        }
      }
    case SCOUTINGPROFILE_ADD_SUCCESS:
      const scoutingProfileId = action.payload['mysql_scout_profile_id'];
      const updatedInfo = {
        ...state.info,
        ...{ [scoutingProfileId]: action.payload },
      };
      teamId = action.payload['team_id'];
      if (teamId > 1) {
        const updatedTeamInfo = {
          ...state.team,
          ...{ [teamId]: action.payload },
        };
        return Object.assign(
          {},
          state,
          { info: updatedInfo, lastScoutingProfileId: scoutingProfileId },
          { team: updatedTeamInfo },
        );
      } else {
        let instId = action.payload['inst_id'];
        let sportId = action.payload['sport_id'];
        let instSportInfo = {};
        if (state.inst && state.inst.hasOwnProperty(instId)) {
          // this sport and inst already exisit
          instSportInfo = {
            ...state.inst[instId],
            ...{ [sportId]: action.payload },
          };
          const updatedInstInfo = {
            ...state.inst,
            ...{ [instId]: instSportInfo },
          };
          return Object.assign(
            {},
            state,
            { info: updatedInfo, lastScoutingProfileId: scoutingProfileId },
            { inst: updatedInstInfo },
          );
        } else {
          instSportInfo = { [instId]: { [sportId]: action.payload } };
          const updatedInstInfo = { ...state.inst, ...instSportInfo };
          return Object.assign(
            {},
            state,
            { info: updatedInfo, lastScoutingProfileId: scoutingProfileId },
            { inst: updatedInstInfo },
          );
        }
      }
    default:
      return state;
  }
};

export default scoutingProfileReducer;

import TokenManager from './tokenManager';
import { refreshToken } from '../store/actions/auth';
import sendToAnalytics from '../utils/analytics';
import { store } from '../store';

export const clearRedux = () => {
  store.dispatch({ type: 'DESTROY_SESSION' });
};

export const logout = () => {
  /* Set current tab data */
  if (window.location.pathname !== '/signin') {
    const url = `${window.location.pathname}${window.location.hash}`;
    window.sessionStorage.setItem('loggedOutInSameSession', url);
    window.sessionStorage.setItem('loggedoutUser', TokenManager.getUserId());
  }
  /* Set other open tabs data */
  window.localStorage.setItem('loggedout', TokenManager.getUserId());
  localStorage.clear();
  clearRedux();
  /* wait for localStorage to be cleared */
  setTimeout(() => {
    window.location.pathname !== '/signin' &&
      window.open(`${window.location.origin}/signin`, '_self');
  }, 200);
};

export const handleAPIError = async (response) => {
  if (!response.ok) {
    console.log('API e: ', response.status);
    sendToAnalytics(JSON.stringify(response));
    if (response.status === '401' && TokenManager.getRefresh()) refreshToken();
    else if (response.status === '401') logout();
  }
  return response;
};

import { getRequest, postJSONRequest } from '../../utils/requests';
import { handleAPIError } from '../../utils/apiResponeHandler';
import { gt_config } from '../../configs';

export const SCOUT_PROFILE_ADD_REQUEST = 'SCOUT_PROFILE_ADD_REQUEST';
export const SCOUT_PROFILE_ADD_SUCCESS = 'SCOUT_PROFILE_ADD_SUCCESS';
export const SCOUT_PROFILE_ADD_FAILURE = 'SCOUT_PROFILE_ADD_FAILURE';
export const SCOUT_PROFILE_REQUEST = 'SCOUT_PROFILE_REQUEST';
export const SCOUT_PROFILE_SUCCESS = 'SCOUT_PROFILE_SUCCESS';
export const SCOUT_PROFILE_FAILURE = 'SCOUT_PROFILE_FAILURE';

const requestFetchProfile = () => {
  return {
    type: SCOUT_PROFILE_REQUEST,
  };
};

const receiveFetchProfile = (scoutId, profile) => {
  return {
    type: SCOUT_PROFILE_SUCCESS,
    payload: { [scoutId]: profile },
  };
};

const fetchProfileError = (error) => {
  return {
    type: SCOUT_PROFILE_FAILURE,
    payload: error,
  };
};

const requestAddProfile = () => {
  return {
    type: SCOUT_PROFILE_ADD_REQUEST,
  };
};

const receiveAddProfile = (profileData) => {
  return {
    type: SCOUT_PROFILE_ADD_SUCCESS,
    payload: profileData,
  };
};

const addProfileError = (error) => {
  return {
    type: SCOUT_PROFILE_ADD_FAILURE,
    payload: error,
  };
};

export const addProfile = (scoutId, data) => async (dispatch) => {
  dispatch(requestAddProfile());
  try {
    const response = await fetch(
      postJSONRequest(`${gt_config.urls.sports}scout_profile/${scoutId}`, data),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    if (json.status) return dispatch(receiveAddProfile(json.data));

    return dispatch(addProfileError(json.msg));
  } catch (error) {
    console.error(error);
    return dispatch(addProfileError(error));
  }
};

export const fetchProfile = (scoutId) => async (dispatch) => {
  dispatch(requestFetchProfile());
  try {
    const response = await fetch(
      getRequest(`${gt_config.urls.sports}scout_profile/${scoutId}`),
    );
    const res = await handleAPIError(response);
    const json = await res.json();
    return dispatch(receiveFetchProfile(scoutId, json.data));
  } catch (error) {
    console.error(error);
    return dispatch(fetchProfileError(error));
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import TokenManager from '../utils/tokenManager';

const UnguardedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props) =>
        TokenManager.get() === null || TokenManager.getRefresh() === null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROUTES.HOME }} />
        )
      }
      {...rest}
    />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(UnguardedRoute);

import GlobalStyles from '@mui/material/GlobalStyles';

const InjectGlobalStyles = (
  <GlobalStyles
    styles={{
      '.MuiSelect-selectMenu, .MuiMenuItem-root': {
        whiteSpace: 'normal',
      },
      '.MuiFormControl-root': {
        width: '100%',
      },
    }}
  />
);

export default InjectGlobalStyles;
